import React from 'react'
import { FastField } from 'formik'

import Input from './Input'

/**
 * This component leverages Formik's `<FastField/>`, which reduces rerenders.
 * Only use this component when a form begins to slow down.
 * See all relevant caveats in Formik's docs: https://formik.org/docs/api/fastfield
 */
const FastInputText = (props) => (
  <FastField name={props.name}>
    {({ field, meta }) => <Input {...props} {...field} {...meta} />}
  </FastField>
)

export default FastInputText

import styled from 'styled-components'
import { css } from '@styled-system/css'

import Grid from './Grid'

const MainContainer = styled(Grid)((props) =>
  css({
    gridTemplateColumns: [
      `
        [full-start]
          0px
            [main-start]
              ${props.theme.constants.grid.templateColumns}
            [main-end]
          0px
        [full-end]
      `,
      `
        [full-start]
          calc(64px - ${props.theme.constants.grid.columnGap[1]})
            [main-start]
              ${props.theme.constants.grid.templateColumns}
            [main-end]
          calc(64px - ${props.theme.constants.grid.columnGap[1]})
        [full-end]
      `,
      `
        [full-start]
          calc(96px - ${props.theme.constants.grid.columnGap[1]})
            [main-start]
              ${props.theme.constants.grid.templateColumns}
            [main-end]
          calc(96px - ${props.theme.constants.grid.columnGap[1]})
        [full-end]
      `,
      `
        [full-start]
          minmax(calc(128px - ${props.theme.constants.grid.columnGap[1]}), calc((100% - 1392px) / 2))
            [main-start]
              ${props.theme.constants.grid.templateColumns}
            [main-end]
          minmax(calc(128px - ${props.theme.constants.grid.columnGap[1]}), calc((100% - 1392px) / 2))
        [full-end]
      `
    ],
    gridColumnGap: props.theme.constants.grid.columnGap
  })
)

export default MainContainer

import React, { useEffect, Fragment } from 'react'
import { useFormikContext } from 'formik'

import { useSubscribeContext } from 'context'
import { ErrorBoundary } from 'helpers'

import HR from 'ui/bend/elements/HR'
import Feedback from 'ui/bend/elements/forms/Feedback'
import Collapse from 'ui/bend/animations/Collapse'

import MembershipPlan from './MembershipPlan'

const MembershipPlanPicker = () => {
  const fieldName = 'selectedPlan'
  const { state } = useSubscribeContext()
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    // Keep formik state and Subscribe state synced.
    if (
      state.membership.selectedPlan &&
      values.selectedPlan !== state.membership.selectedPlan.id
    ) {
      setFieldValue(fieldName, state.membership.selectedPlan.id)
    }
  }, [state.membership.selectedPlan, values.selectedPlan])

  return (
    <Fragment>
      <HR my='16px' />
      {state.membership.plans &&
        state.membership.plans.map((plan, index) => (
          <Fragment key={plan.id}>
            <MembershipPlan
              plan={plan}
              name={fieldName}
              isAnOffer={state.isAnOffer}
            />
            {index < state.membership.plans.length - 1 && (
              <HR ml='32px' mb='16px' />
            )}
          </Fragment>
        ))}
      <Collapse isOpened={!!state.membership.selectedPlanError}>
        <Feedback className='error' mt='0px' pl='30px' pb='16px'>
          {state.membership.selectedPlanError}
        </Feedback>
      </Collapse>
      <HR mb='16px' />
    </Fragment>
  )
}

const SafeMembershipPlanPicker = () => (
  <ErrorBoundary>
    <MembershipPlanPicker />
  </ErrorBoundary>
)

export default SafeMembershipPlanPicker

import styled from 'styled-components'
import { variant } from 'styled-system'

import Text from './Text'

// We also use CTA values within the Button.js config.
// We define their defaultValues in an object and destructure it where needed.

export const defaultValues = {
  fontFamily: 'Arquitecta',
  fontWeight: 'bold',
  fontSize: '15px',
  letterSpacing: 3,
  lineHeight: '140%',
  textTransform: 'uppercase'
}

const CTA = styled(Text)(
  variant({
    variants: {
      default: {
        ...defaultValues
      },
      small: {
        ...defaultValues,
        fontWeight: 'bold',
        fontSize: 2
      },
      responsive: {
        ...defaultValues,
        fontWeight: 'bold',
        fontSize: ['14px', '15px', '15px']
      }
    }
  })
)

CTA.defaultProps = {
  variant: 'default'
}

export default CTA

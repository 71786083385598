import { createGlobalStyle } from 'styled-components'
import VideoCSS from 'video.js/dist/video-js.css'

// -moz-osx-font-smoothing
//    Fixes Firefox bug where higher font-weights are rendered in bold.

// #react-body
//    We expand the height of the react-rails react_component div to be at least 100vh so that the footer will be placed appropriately.
//    If we didn't do this then the react-rails container will only take up as much space as it 'needs', pulling the footer up.

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scroll-behavior: smooth; 
    -webkit-scroll-behavior: smooth;
    -moz-scroll-behavior: smooth;
    -ms-scroll-behavior: smooth;
  }

  body {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.primary};
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 16px;

    #react-body {
      position: relative;
      min-height: 100vh;
    }

    &.prevent-scroll {
      height: 100vh;
      overflow: hidden;
      touch-action: none;
    }

    &.using-mouse {
      *:focus {
        outline: none;
      }
    }
  }

  a, .as-link {
    &.underline {
      text-decoration: underline;
    }
    text-decoration: none;
    cursor: pointer;
    transition: color 0.25s ease-in-out;
    color: ${(props) => props.theme.colors.primary};
    
    &:focus {
      color: inherit;
    }
  }


  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    /* Chrome adds in margin-block-start and -end to h elements. */
    margin-block-start: unset;
    margin-block-end: unset;
    margin-inline-start: unset;
    margin-inline-end: unset;
  }

  label {
    margin-bottom: 0;
  }

  form {
    padding: 0;
  }

  input {
    padding: 0;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.grey4};
    opacity: 1;
  }

  img {
    vertical-align: middle;
  }

  ul {
    margin-block-end: 0px;

    &:not(.as-list) {
      margin-block-start: 0px;
      padding-inline-start: 0px;
      list-style-type: none;
    }
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
  }

  ${VideoCSS}
`
export default GlobalStyle

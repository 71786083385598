import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { useField } from 'formik'

import { useSubscribeContext } from 'context'

import { Badge } from 'ui/bend/elements'
import { Caption, P } from 'ui/bend/typography'
import Text from 'ui/bend/typography/Text'

import RadioButton, {
  RadioButtonContainer,
  RadioButtonLabel,
  RadioButtonInput
} from '../FormElements/RadioButton'

const Details = styled(P)`
  color: ${(props) => props.theme.colors.grey6};

  ${RadioButtonContainer}:hover & {
    color: ${(props) => props.theme.colors.primary};
  }

  ${RadioButtonInput}:checked + ${RadioButtonLabel} & {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
  }

  ${RadioButtonInput}:disabled + ${RadioButtonLabel} & {
    color: ${(props) => props.theme.colors.grey4};
  }
`

const Price = styled(Text)(
  css({
    fontSize: 5,
    fontFamily: 'Proxima',
    lineHeight: '135%',
    fontWeight: '600'
  })
)

const MembershipPlan = ({ plan, ...props }) => {
  const { isAnOffer } = props
  const { state, actions } = useSubscribeContext()

  const id = `membership_plan_${plan.id}`
  const inputProps = {
    id: id,
    value: plan.id,
    ...props
  }
  const [field] = useField({ ...inputProps, type: 'radio' })
  const { isMwe } = state?.membership

  const planTitle = () => {
    switch (plan.frequency) {
      case 'yearly':
        return 'annually'
      default:
        return plan.frequency
    }
  }

  const handleSelect = (e) => {
    if (!field.checked) {
      actions.membership.selectPlan(e.target.value)
      field.onChange(e)
    }
  }

  return (
    <RadioButton
      id={id}
      {...field}
      {...inputProps}
      onChange={handleSelect}
      containerProps={{ mb: '18px' }}
    >
      <Details>
        <span style={{ textTransform: 'capitalize' }}>{planTitle()}</span>
        {!isAnOffer && plan.comparisonDiscount && (
          <Badge variant={plan.featured ? 'featured' : 'solid'} ml='8px'>
            Save {plan.comparisonDiscount}
            {plan.frequency === 'yearly' && ` + ONE-TIME 20% OFF ALO YOGA`}
          </Badge>
        )}
      </Details>

      <Caption display='inline-block' color='grey5' my='4px'>
        {plan.frequency === 'yearly' && !isAnOffer && plan.comparisonDiscount
          ? `${plan.description.slice(
              0,
              -1
            )} and you will receive an email with a one-time discount for 20% off Alo Yoga.`
          : plan.description}
      </Caption>

      <Details>
        {isMwe ? (
          <>
            <Price>${plan.recurringPrice}</Price> /{' '}
            {plan.frequency === 'monthly' ? 'month' : 'year'}{' '}
            <s>
              reg ${plan.regularPrice} /{' '}
              {plan.frequency === 'monthly' ? 'month' : 'year'}
            </s>
          </>
        ) : (
          <>
            <Price>${plan.equivalentMonthlyPrice}</Price> / month
            {plan.frequency !== 'monthly' && ` billed ${planTitle()}`}
          </>
        )}
      </Details>
    </RadioButton>
  )
}

MembershipPlan.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    comparisonMonthlyPrice: PropTypes.string,
    comparisonDiscount: PropTypes.string,
    equivalentMonthlyPrice: PropTypes.string.isRequired,
    featured: PropTypes.bool.isRequired,
    trialEndDate: PropTypes.string,
    rolloverEndDate: PropTypes.string,
    couponApplies: PropTypes.bool,
    description: PropTypes.string.isRequired
  }).isRequired
}

export default MembershipPlan

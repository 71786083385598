import styled from 'styled-components'
import { border, compose, layout, space, system } from 'styled-system'

const Image = styled('img')(
  compose(
    border,
    layout,
    space,
    system({
      objectFit: true
    })
  )
)

Image.defaultProps = {
  maxWidth: '100%'
}

export default Image

export default [
  '13px',
  '14px',
  '16px',
  '17px',
  '18px',
  '19px',
  '21px',
  '24px',
  '29px',
  '37px',
  '50px',
  '71px'
]

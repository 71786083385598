/*
| This component is used to create responsive SVG icons/images that resize according to media queries.
| It accepts the following props:
| height: An array of strings outlining the height at different breakpoints, according to Styled-System specifications.
| width: An array of strings outlining the width at different breakpoints, according to Styled-System specifications.
| display: CSS value for display (defaults to inline-block)
| inline: will set element as span and automatically set height & width to 1em and display to inline-flex. This allows the icon to be used inline with text.
| SVG: The imported SVG node.
|
| Example Usage:
| import { SVGImage } from 'ui/bend'
| import { Icon } from 'images'
|
| // React Code here...
| const sizeArray = {['20px', '24px', '32px']}
| <SVGImage height={sizeArray} width={sizeArray} SVG={Icon}/>
*/

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { system } from 'styled-system'

import { ErrorBoundary } from 'helpers'
import Box from './Box'

const SVGContainer = styled(Box)(
  system({
    fill: {
      property: 'fill',
      scale: 'colors'
    },
    stroke: {
      property: 'stroke',
      scale: 'colors'
    }
  }),
  {
    transition: 'fill 0.25s ease-in-out'
  }
)

const SVGImage = ({
  SVG,
  width,
  height,
  display = 'inline-block',
  inline,
  backgroundColor,
  ...rest
}) => {
  return (
    <SVGContainer
      as={inline && 'span'}
      width={inline ? '.85em' : width}
      height={inline ? '.85em' : height}
      display={inline ? 'inline-flex' : display}
      {...rest}
    >
      {SVG && (
        <SVG
          fill={rest.fill}
          style={{
            height: 'inherit',
            width: 'inherit',
            backgroundColor: backgroundColor
          }}
        />
      )}
    </SVGContainer>
  )
}

SVGImage.propTypes = {
  SVG: PropTypes.any.isRequired,
  width: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  inline: PropTypes.bool,
  display: PropTypes.string
}

const SafeSVGImage = (props) => (
  <ErrorBoundary>
    <SVGImage {...props} />
  </ErrorBoundary>
)

export default SafeSVGImage

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { Flex } from 'ui/bend/elements'

export const RadioButtonContainer = styled(Flex)(
  css({
    mb: '16px'
  })
)

export const RadioButtonInput = styled('input')(
  css({
    cursor: 'pointer',
    position: 'absolute',
    opacity: 0,

    '&:hover + label::before': {
      borderColor: 'primary'
    },

    '&:checked + label::before': {
      borderWidth: '2px',
      borderColor: 'primary'
    },

    '&:checked + label::after': {
      backgroundColor: 'primary'
    },
    '&:disabled + label': {
      cursor: 'default'
    },

    '&:disabled + label::before': {
      backgroundColor: 'grey2',
      borderColor: 'grey2'
    },

    '&:disabled + label::after': {
      backgroundColor: 'grey2'
    }
  })
)

export const RadioButtonLabel = styled('label')(
  css({
    cursor: 'pointer',
    pl: '32px',
    position: 'relative',
    width: '100%',

    '&::before': {
      content: "''",
      position: 'absolute',
      display: 'inline-block',
      left: '0px',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'grey5'
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      display: 'inline-block',
      left: '4px',
      top: '4px',
      width: '12px',
      height: '12px',
      borderRadius: '50%'
    }
  })
)

const RadioButton = ({ children, containerProps, ...props }) => {
  return (
    <RadioButtonContainer {...containerProps}>
      <RadioButtonInput type='radio' {...props} />

      <RadioButtonLabel htmlFor={props.id}>{children}</RadioButtonLabel>
    </RadioButtonContainer>
  )
}

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default RadioButton

// Current breakpoints are related to tablet size. Will discuss further w/ Mari and Trista about our ideal breakpoints.

// Styled-System requires that breakpoints array be strings indicating their type (px, em, etc.)
// However, carousel objects (e.g. react-id-swipe) use raw numbers to define breakpoints.
// So we can have consistency across all media, we export a breakpoints array with the raw numbers.
// We also format them into '#px' array format for our styled-system theme
const breakpoints = [640, 1024, 1200]

const formattedBreakpoints = breakpoints.map((point) => point + 'px')

export { breakpoints, formattedBreakpoints }

import styled from 'styled-components'
import { variant } from 'styled-system'

import Text from './Text'

export const defaultValues = {
  fontFamily: 'Proxima',
  fontSize: 2,
  fontWeight: 'regular',
  lineHeight: '140%'
}

const P = styled(Text)(
  variant({
    variants: {
      default: {
        ...defaultValues
      },
      bold: {
        ...defaultValues,
        fontWeight: 'semibold'
      }
    }
  })
)

P.defaultProps = {
  as: 'p',
  variant: 'default'
}

export default P

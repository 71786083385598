import React from 'react'

import { Play } from 'images/bend'
import { Flex, SVGImage } from 'ui/bend/elements'

const PlayButton = (props) => {
  // TODO: Redeem-v2 : Get tablet sizing from Isaac.
  return (
    <Flex
      bg='white'
      borderRadius='50%'
      padding={['20px', '32px']}
      as='button'
      justifyContent='center'
      alignItems='center'
      height={['64px', '96px']}
      width={['64px', '96px']}
      border='none'
      {...props}
    >
      <SVGImage width={['24px', '32px']} height={['24px', '32px']} SVG={Play} />
    </Flex>
  )
}

export default PlayButton

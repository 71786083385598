import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'

import { Feedback } from 'ui/bend/elements'
import { Collapse } from 'ui/bend/animations'
import { Container, Label, BaseInput as Input } from './Input'

// TODO: Refactor this to use the Input component directly
const InputText = React.forwardRef(
  (
    {
      label,
      feedback,
      children,
      containerProps,
      labelProps,
      customError,
      placeholder,
      noFocus,
      ...props
    },
    ref
  ) => {
    // Track whether input currently has focus.
    const focused = useRef(false)

    const [field, meta, helper] = useField(props)
    const formikObject = useFormikContext()
    // if there are prefilled values (or a placeholder present), then set floating to true.
    const [floating, setFloating] = useState(
      (field.value && field.value.length > 0) ||
        typeof field.value === 'number' ||
        Boolean(props.placeholder)
    )

    // Used in case the value is set outside of the current component, so neither the onBlur nor
    // the onFocus events would be fired.
    useEffect(() => {
      // A user can delete the current value but remain focused on the field.
      setFloating(
        (field.value && field.value.length > 0) ||
          typeof field.value === 'number' ||
          focused.current
      )
    }, [field.value])

    const hasError = meta.touched && meta.error

    const handleFocus = (e) => {
      if (!noFocus) {
        if (props.onFocus && typeof props.onFocus === 'function')
          props.onFocus(e, helper, formikObject)
        focused.current = true
        setFloating(true)
      }
    }

    const handleBlur = (e) => {
      if (!noFocus) {
        if (props.onBlur && typeof props.onBlur === 'function') {
          props.onBlur(e, helper, formikObject)
        } else {
          field.onBlur(e)
        }

        if (
          typeof field.value !== 'number' &&
          !field.value &&
          !props.placeholder
        ) {
          setFloating(false)
        }
        focused.current = false
      }
    }

    return (
      <Container className={props.className} {...containerProps}>
        <Label
          {...labelProps}
          htmlFor={props.id || props.name}
          className={
            (floating || ['date', 'time'].includes(props.type)) && 'floating'
          }
          disabled={props.disabled}
        >
          {label}
        </Label>
        <Input
          ref={ref}
          id={props.name}
          {...field}
          {...props}
          placeholder={placeholder}
          className={hasError && 'error'}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <Collapse isOpened={!!hasError}>
          {hasError && (
            <Feedback className='error'>
              {customError && typeof customError === 'function'
                ? customError(meta.error)
                : meta.error}
            </Feedback>
          )}
        </Collapse>
        <Collapse isOpened={!hasError && !!feedback}>
          {!hasError && feedback && <Feedback>{feedback}</Feedback>}
        </Collapse>

        {children}
      </Container>
    )
  }
)

InputText.displayName = 'InputText'

InputText.propTypes = {
  label: PropTypes.string,
  feedback: PropTypes.string,
  children: PropTypes.node,
  noFocus: PropTypes.bool
}

export default InputText

import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useSubscribeContext } from 'context'
import { ErrorBoundary } from 'helpers'
import Feedback from 'ui/bend/elements/forms/Feedback'

const Store = createContext(null)
export const useMultiStepForm = () => useContext(Store)

const MultiStepForm = ({
  children,
  firstStep = 0,
  onComplete,
  onFailComponent
}) => {
  const { actions, initialValues, validators } = useSubscribeContext()

  const [stepNumber, setStepNumber] = useState(firstStep)
  const [showFailComponent, setShowFailComponent] = useState(null)

  // We clone the child elements and inject stepNumber and that elements index (i.e. where it is along the form).
  // We could provide stepNumber via context to be more explicit in our composition, but that would not give access to the step's index.
  const steps = React.Children.toArray(children)

  const currentStep = steps[stepNumber]
  const isLastStep = stepNumber === steps.length - 1

  const nextStep = () => {
    setStepNumber(stepNumber + 1)
  }

  const goToStep = (id) => {
    if (id >= 0 && id < steps.length - 1) {
      setStepNumber(id)
    }
  }

  const handleSubmit = async (values, formikObject) => {
    try {
      if (
        currentStep.props.onSubmit &&
        typeof currentStep.props.onSubmit === 'function'
      ) {
        const res = await currentStep.props.onSubmit(values, formikObject)
        if (res) {
          if (!isLastStep) {
            formikObject.setTouched({})
            nextStep()
          } else {
            if (onComplete && typeof onComplete === 'function')
              await onComplete(values, formikObject)
          }
        }
      } else {
        throw Error(
          'No submission handler. Please add an onSubmit function to each form section.'
        )
      }
    } catch (error) {
      actions.handleError(error, { ...formikObject })
      if (isLastStep && onFailComponent) {
        setShowFailComponent(true)
      }
    }
  }

  /* Update validationSchema when the currentStep's type changes */
  const currentValidation = useMemo(() => {
    const resolveValidators = (path) => {
      // We recursively search down an object based on dot-separated values.
      // This might be useful to factor out into a helper.
      return path
        .split('.')
        .reduce((prev, curr) => (prev ? prev[curr] : null), validators)
    }

    const parseValidators = (type) => {
      if (Array.isArray(type)) {
        // If type is an array, we want to collect all the validators that correspond to the types in the array into a single object.
        return type.reduce(
          (prev, curr) => ({ ...prev, ...resolveValidators(curr) }),
          {}
        )
      } else {
        return resolveValidators(type)
      }
    }

    if (currentStep && currentStep.props && currentStep.props.type)
      return parseValidators(currentStep.props.type)
  }, [currentStep, validators])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape(currentValidation)}
    >
      {({ values, status, isSubmitting }) => (
        <Form>
          <Store.Provider value={{ stepNumber, goToStep }}>
            {steps}
          </Store.Provider>
          {status && (
            <Feedback className='error' ml='0px' mt='15px'>
              {status}
            </Feedback>
          )}
          {showFailComponent && onFailComponent({ isSubmitting })}
        </Form>
      )}
    </Formik>
  )
}

MultiStepForm.propTypes = {
  firstStep: PropTypes.number,
  onComplete: PropTypes.func,
  shouldRetry: PropTypes.bool,
  children: PropTypes.node.isRequired
}

const SafeMultiStepForm = (props) => (
  <ErrorBoundary>
    <MultiStepForm {...props} />
  </ErrorBoundary>
)

export default SafeMultiStepForm

import styled from 'styled-components'
import { flexbox } from 'styled-system'

import Grid from './Grid'

// Add flex elements to a Grid item.
const GridItem = styled(Grid)(flexbox)

GridItem.defaultProps = {
  display: 'flex'
}

export default GridItem

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const MarqueeWrapper = styled('div')`
  width: 100%;
  height: auto;
  overflow: hidden;
`

const marqueeAnimation = (offset, containerWidth) => keyframes`
    0% {
    transform: translateX(${containerWidth}px);
    visibility: visible;
  }
  100% {
    transform: translateX(-${offset}px);
  }
`

const MarqueeIpse = styled('div')`
  display: inline-block;
  animation-iteration-count: ${(props) =>
    props.scrolledOnce ? 'infinite' : '1'};
  animation-timing-function: linear;
  animation-play-state: ${(props) => (props.playing ? 'running' : 'paused')};
  /* The first animation begins with the marquee left-aligned with the wrapper container */
  animation-name: ${(props) =>
    props.scrolledOnce
      ? marqueeAnimation(props.offset, props.containerWidth)
      : marqueeAnimation(props.offset, 0)};
  animation-duration: ${(props) =>
    props.duration
      ? props.scrolledOnce
        ? `${props.duration + 3}s`
        : `${props.duration}s`
      : ''};
  animation-delay: ${(props) => (props.scrolledOnce ? '' : '1s')};

  &:hover {
    animation-play-state: paused;
  }
`
/**
 * Creates a marquee effect to scroll text left to right within an auto-sized container.
 * When a child is updated, it will reset the marquee animation.
 * Completion of the first marquee (i.e. first text disappearing animation) will fire an optionally provided callback
 *
 * @param {number} animationSpeed - The speed at which a pixel will move from right to left across the width of the marquee. Note this is NOT the total animation length, but rather the visible animation length.
 * @param {function} callback - Optional functional callback for when the first marquee child disappears.
 * @param {number} callbackTimeout - The delay for the marquee callback, used when we want to fire callbacks consistently regardless of marquee flowing or not.
 * @param {number} width - The width of the containing Marquee element
 * @param {node} children - child component
 */
const Marquee = ({
  animationSpeed = 3,
  callback,
  callbackTimeout = 4000,
  width = 0,
  children
}) => {
  const ref = useRef()
  const timeoutRef = useRef()
  const [childWidth, setChildWidth] = useState()
  const [duration, setDuration] = useState()

  const [shouldPlay, setShouldPlay] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [scrolledOnce, setScrolledOnce] = useState(false)

  const handleMouseOver = () => {
    if (shouldPlay) {
      // We only need to toggle playing if the marquee should scroll.
      // Otherwise, we can accidentally trigger a scroll when we don't intend to (e.g. child is too small to bother)
      setPlaying(!playing)
    }
  }

  /* Reset scroll state when a new child is rendered. */
  useEffect(() => {
    setScrolledOnce(false)
  }, [children])

  /* Determine width of child content and attach event listeners */
  useEffect(() => {
    const handleAnimationEnd = () => {
      if (callback && typeof callback === 'function') {
        callback()
      }
      setScrolledOnce(true)
    }

    if (ref.current && children) {
      setChildWidth(ref.current.offsetWidth)

      /* After the first animation is run trigger any callbacks and switch animation over to infinite scroll. */
      ref.current.addEventListener('animationend', handleAnimationEnd)
    }

    return () => {
      if (ref.current && children) {
        ref.current.removeEventListener('animationend', handleAnimationEnd)
      }
    }
  }, [ref, children])

  /* Once we know how large our container and scrolling child are, calculate how long it will take to scroll and begin animation */
  useEffect(() => {
    if (childWidth && width) {
      if (childWidth <= width) {
        setShouldPlay(false)
        if (callback && typeof callback === 'function') {
          // This will allow us to run a timed callback even if the marquee doesn't animate (e.g. song title is too short in MusicPlayerMarquee)
          // We might have to adjust this code to prevent an initial callback firing if we use <Marquee/> in a different setting with a different purpose.
          timeoutRef.current = setTimeout(callback, callbackTimeout)
        }
      } else {
        setShouldPlay(true)
        // The logic behind this formula is that we want any child to take animationSpeed seconds to pass through one wrapperWidth.
        // Therefore, we take the length of our child element and subdivide it into n wrapperWidths. These wrapperWidths will each take animationSpeed time.
        const animationDuration = (childWidth / width) * animationSpeed
        setDuration(animationDuration)
      }
    }

    return () => {
      if (timeoutRef.current) {
        // Dispose of our timeout if it isn't needed anymore.
        // This is to prevent a bug where a stale timeout could dismiss a new child from the marquee if a user skips around on a video.
        clearTimeout(timeoutRef.current)
      }
    }
  }, [childWidth, width])

  /* Playing should always reflect shouldPlay state, unless handleMouseOver is called */
  useEffect(() => {
    setPlaying(shouldPlay)
  }, [shouldPlay])

  return (
    <MarqueeWrapper
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOver}
    >
      <MarqueeIpse
        playing={playing}
        duration={duration}
        ref={ref}
        offset={childWidth}
        containerWidth={width}
        scrolledOnce={scrolledOnce}
      >
        {children}
      </MarqueeIpse>
    </MarqueeWrapper>
  )
}

Marquee.propTypes = {
  animationSpeed: PropTypes.number,
  callback: PropTypes.func,
  callbackTimeout: PropTypes.number,
  children: PropTypes.node.isRequired
}

export default Marquee

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Slider = styled('span')`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  background: ${(props) => props.theme.colors.grey4};
  border-radius: 24px !important; // TODO: React refactor - important here for legacy pages.

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 50%;
    left: 2px;
    top: 2px;
    background-color: ${(props) => props.theme.colors.base};
    transition: 0.4s;
  }
`

const SwitchContainer = styled('label')`
  position: relative;
  display: inline-block;
  height: 24px;
  width: 40px;

  /* hide default input */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + ${Slider} {
    background-color: ${(props) => props.theme.colors.primary};
  }

  input:checked + ${Slider}:before {
    transform: translateX(16px);
  }
`

const Switch = ({ label, ...props }) => {
  return (
    <SwitchContainer>
      <input type='checkbox' aria-label={label} {...props} />
      <Slider />
    </SwitchContainer>
  )
}

Switch.propTypes = {
  label: PropTypes.string.isRequired
}

export default Switch

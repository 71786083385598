import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'

import SVGImage from './SVGImage'
import { X } from 'images/bend'

import Spinner from 'ui/bend/animations/Spinner'
import StyledButtonBase from './StyledButtonBase'

// TODO: Might make this a subset of Button... they're SOOOooo close.

// TODO:
// - Processing state
//

const StyledButton = styled(StyledButtonBase)`
  border-radius: 100px;
  padding: 6px;
  border: none;
`

const CloseButton = ({
  children,
  disabled,
  processing,
  variant = 'solid',
  ...rest
}) => {
  const { colors } = useTheme()

  return (
    <StyledButton
      disabled={disabled || processing}
      className={processing && 'processing'}
      variant={variant}
      height='24px'
      width='24px'
      {...rest}
    >
      {processing ? (
        <Spinner />
      ) : (
        <SVGImage SVG={X} inline fill={colors.primary} />
      )}
    </StyledButton>
  )
}

CloseButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default CloseButton

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Flex } from 'ui/bend/elements'

const Bullet = styled(Flex)`
  height: 16px;
  width: 16px;
  padding: 4px;
  cursor: pointer;
  &:after {
    content: '';
    background-color: ${(props) =>
      props.isActive ? props.theme.colors.primary : props.theme.colors.grey3};
    height: 100%;
    width: 100%;
    border-radius: 100px;
    transition: background-color 0.3s ease-in-out;
  }
`

const PaginationBullets = ({
  activeIndex,
  goToSlide,
  maxBullets = 5,
  slides,
  ...rest
}) => {
  return (
    <Flex justifySelf='center' {...rest}>
      {slides.map((slide, index) => {
        if (index < maxBullets) {
          return (
            <Bullet
              key={index}
              onClick={() => goToSlide(index)}
              isActive={activeIndex === index}
            />
          )
        }
      })}
    </Flex>
  )
}

PaginationBullets.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  goToSlide: PropTypes.func.isRequired,
  maxBullets: PropTypes.number,
  slides: PropTypes.array.isRequired
}

export default PaginationBullets

const colors = {
  white: '#FFFFFF',
  black: '#000000',
  alto: '#CCCCCC',
  dawn: '#A3A3A3',
  charcoal: '#242424',
  chicago: '#636363',
  error: '#C62828',
  featured: '#B00020',
  mercury: '#E1E1E1',
  mine: '#3b3b3b',
  smoke: '#F2F2F2 ',
  errorLight: '#FFF6F6',
  alert: {
    light: '#E7CCCF',
    medium: '#B00020',
    dark: '#740031'
  },
  highlight: {
    light: '#F1E8DD',
    medium: '#CEB18F',
    dark: '#826646'
  },
  success: {
    light: '#D8E1D5',
    medium: '#758E6D',
    dark: '#3C4B37'
  },
  accent: {
    light: '#D7DDEB',
    medium: '#7183B0',
    dark: '#384669'
  }
}

export const rgb = {
  white: '255, 255, 255',
  black: '0, 0, 0'
}

export default colors

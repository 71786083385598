import styled from 'styled-components'
import { css } from '@styled-system/css'

import Text from './Text'

const Blockquote = styled(Text)(
  css({
    fontFamily: 'Arquitecta',
    fontWeight: 'regular',
    fontSize: [8, 9, 10],
    lineHeight: '112%',
    textAlign: 'center'
  })
)

Blockquote.defaultProps = {
  as: 'div'
}

export default Blockquote

import styled from 'styled-components'
import {
  background,
  border,
  color,
  compose,
  layout,
  position,
  space,
  system
} from 'styled-system'

const Box = styled('div')(
  compose(background, border, color, layout, position, space),
  system({
    cursor: true
  })
)

export default Box

import constants from './constants'

import {
  componentHeights,
  fonts,
  fontSizes,
  fontWeights,
  formattedBreakpoints as breakpoints,
  letterSpacings,
  mediaQueries,
  spacing
} from './scales'

const baseTheme = {
  breakpoints,
  componentHeights,
  constants,
  fonts,
  fontSizes, // only used in new styles
  fontWeights,
  letterSpacings,
  mediaQueries,
  spacing
}

export default baseTheme

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useCombobox } from 'downshift'

import { Flex } from 'ui/bend/elements'
import { Chevron } from 'ui/bend/animations'

import Input from './Input'
import Dropdown, {
  DropdownItem,
  DropdownButton,
  DropdownInlineContext,
  DropdownSpinner
} from '../Dropdown'

/**
 * The `<Combobox>` component (also known as a type-ahead select)
 * is used to provide an interface for end-users to type into an input and be given a dropdown selection
 * of options to choose from.
 *
 * To use the `<Combobox>` component, you just need to supply it with an array of objects that have a `value`
 * and `label` property. You'll also need to implement the search functionality of the Combobox on your own
 * (this is because this component has been primarily used in combination with an api that has fuzzy search
 * implemented).
 *
 *
 * Example usage:
 * <Combobox label='Thingy' items={[{value: "value", label: "Value"}]} />
 *
 * Since it is very common to use this Combobox with a backend API and Formik, we've also created a hook
 * that can handle a lot of the business logic associated with this feature (please see `useComboboxSearchWithFormik`
 * for more details).
 *
 * Notes about usage:
 * - The default behavior for this component is "uncontrolled", but you can pass in a `value` and `setValue`
 *   if you'd like to control the `<Combobox>`'s input.
 *
 * @param {array} items The list of items to render as options for the dropdown menu
 * @param {string} [value] The controlled value for the input (omitting it will default to "uncontrolled" behavior). Note: the value that gets set here will be the label, rather than the actual value. This is because the label is what gets displayed to the user inside of the input element itself, since the value can sometimes be something that is non-human readable (such as a uuid).
 * @param {function} [setValue] The function that's used to control the value of the input (only required when providing `value`).
 * @param {boolean} [showError] Used to render the error message if one is present.
 * @param {string} [error] The error message to display.
 * @param {boolean} [loading] Displays the loading indicator for the dropdown button
 * @param {function} [onBlur] a callback function that fires after the user has unfocused from the input
 * @param {boolean} [canCreateNewItem] allows the caller to specify that the end-user can create a new item within the form field.
 */
const Combobox = ({
  items,
  value,
  inputValue: controlledInputValue,
  setInputValue,
  setValue,
  showError,
  error,
  loading,
  onBlur,
  canCreateNewItem,
  ...props
}) => {
  const [focused, setFocused] = useState(false)

  // Handles the switch to controlled behavior, for when
  // the caller wants to control the input's value (which is useful for Formik integration)
  const controlProps = {}
  if (setValue) {
    controlProps.inputValue = controlledInputValue || ''
    controlProps.onInputValueChange = (changes) => {
      // Only requery if we are changing the input itself.
      const requery = changes.type === useCombobox.stateChangeTypes.InputChange
      setInputValue(changes.inputValue, requery)
      // Always sets the value in formik if the canCreateNewItem prop is passed in,
      // since whatever value they're now passing is valid
      if (canCreateNewItem) setValue(changes.inputValue)
    }
    controlProps.onSelectedItemChange = (changes) =>
      setValue(changes.selectedItem.value)
  }

  const {
    isOpen,
    inputValue,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    items,
    itemToString: (item) => (item ? item.label : ''),
    ...controlProps
  })

  return (
    <Flex
      {...getComboboxProps()}
      position='relative'
      flexDirection='column'
      flex='1 1 auto'
    >
      <Input
        error={showError ? error : undefined}
        {...props}
        {...getInputProps({
          onFocus: () => {
            setFocused(true)
          },
          onBlur: (e) => {
            if (onBlur) onBlur(e)
            setFocused(false)
          }
        })}
        value={inputValue}
        labelProps={getLabelProps()}
      >
        <DropdownInlineContext focused={focused} showError={showError} divider>
          {loading ? (
            <DropdownSpinner />
          ) : (
            <DropdownButton
              {...getToggleButtonProps()}
              aria-label='toggle menu'
              role='button'
              visible={items.length > 0}
            >
              <Chevron direction='down' active={isOpen} />
            </DropdownButton>
          )}
        </DropdownInlineContext>
      </Input>
      <Dropdown
        open={!loading && isOpen && items.length > 0}
        {...getMenuProps()}
      >
        {items.map((item, index) => (
          <DropdownItem
            as='li'
            {...getItemProps({ item, index })}
            selected={index === highlightedIndex}
            key={index}
          >
            {item.label}
          </DropdownItem>
        ))}
      </Dropdown>
    </Flex>
  )
}

Combobox.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.node,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  canCreateNewItem: PropTypes.bool
}

export default Combobox

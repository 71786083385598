import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Collapse } from 'ui/bend/animations'
import { Flex } from 'ui/bend/elements'
import { P } from 'ui/bend/typography'

import { Chevron } from 'ui/acro/animations'
import { HR } from 'ui/acro/elements'

const FAQItem = ({ title, body, selected, handleSelect, index }) => (
  <Fragment>
    <Flex
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      onClick={() => handleSelect(index)}
      onKeyPress={() => handleSelect(index)}
      cursor='pointer'
      aria-controls={`faq-item-${index}`}
      aria-expanded={selected}
      tabIndex='0'
    >
      <P variant='bold' pr='20px'>
        {title}
      </P>
      <Chevron width='13px' height='100%' className={selected && 'flip'} />
    </Flex>
    <Collapse isOpened={selected}>
      <P
        py={['16px']}
        color='grey6'
        id={`faq-item-${index}`}
        dangerouslySetInnerHTML={body}
      />
    </Collapse>
    <HR my='22px' />
  </Fragment>
)

FAQItem.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
}

export default FAQItem

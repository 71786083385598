import styled from 'styled-components'

const ProgressBar = styled('progress')`
  &[value] {
    appearance: none;
    border: none;
    width: 100%;
    height: 2px;

    ::-moz-progress-bar {
      background-color: ${(props) => props.theme.colors.primary};
    }

    ::-webkit-progress-bar {
      background-color: ${(props) => props.theme.colors.grey2};
    }

    ::-webkit-progress-value {
      transition: width 0.25s ease-in-out;
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`

export default ProgressBar

import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import Select from './Select'

const FormikSelect = ({ name, items, label, disabled }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(
    name
  )

  const showError = Boolean(touched && error)

  const handleSetValue = async (value) => {
    // When a user sets an item, we need to manually setTouched, in order to show any potential errors.
    await setValue(value)
    setTouched(true)
  }

  const onMenuBlur = () => {
    // This is for when a user opens the dropdown but doesn't select an item.
    setTouched(true)
  }

  return (
    <Select
      items={items}
      label={label}
      value={value}
      setValue={handleSetValue}
      showError={showError}
      onMenuBlur={onMenuBlur}
      error={error}
      disabled={disabled}
    />
  )
}

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default FormikSelect

import baseTheme from './baseTheme'
import baseColors, { rgb } from './scales/colors'

const colors = {
  ...baseColors, // to support deprecated color references
  base: baseColors.white,
  primary: baseColors.black,
  grey1: baseColors.smoke,
  grey2: baseColors.mercury,
  grey3: baseColors.alto,
  grey4: baseColors.dawn,
  grey5: baseColors.chicago,
  grey6: baseColors.mine,
  grey7: baseColors.charcoal,
  featured: baseColors.featured,
  errorDefault: baseColors.error,
  errorBase: baseColors.errorLight, // This is in flux right now.
  success: baseColors.success,
  rgb: {
    base: rgb.white,
    primary: rgb.black
  }
}

const LightTheme = {
  ...baseTheme,
  colors
}

export default LightTheme

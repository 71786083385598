import styled from 'styled-components'
import { variant } from 'styled-system'

import Text from './Text'

const defaultValues = {
  fontSize: [8, 9, 10],
  fontFamily: 'Arquitecta',
  letterSpacing: 2,
  lineHeight: '108%',
  fontWeight: 'black',
  textTransform: 'uppercase',
  wordBreak: 'break-word'
}

const H1 = styled(Text)(
  variant({
    variants: {
      default: {
        ...defaultValues
      },
      thin: {
        ...defaultValues,
        textTransform: 'none',
        lineHeight: '112%',
        fontWeight: 'normal'
      }
    }
  })
)

H1.defaultProps = {
  as: 'h1',
  variant: 'default'
}

export default H1

import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import Checkbox from './Checkbox'

const FormikCheckbox = ({ name, ...props }) => {
  const [field, meta, helper] = useField(name)

  return <Checkbox name={name} {...field} {...props} />
}

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired
}

export default FormikCheckbox

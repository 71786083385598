import React from 'react'
import PropTypes from 'prop-types'
import { useSelect } from 'downshift'

import { Flex } from 'ui/bend/elements'
import { Chevron } from 'ui/bend/animations'

import Input from './Input'
import Dropdown, {
  DropdownItem,
  DropdownInlineContext,
  DropdownSpinner
} from '../Dropdown'

const VOID_SELECTED = { value: '', label: '' }
/**
 * The `<Select>` component is used to provide an interface for end-users to select from a pre-determined
 * list of options.
 *
 * To use the `<Select>` component, you just need to supply it with an array of objects that have a `value`
 * property.
 *
 * Example usage:
 * <Select label='Thingy' items={[{value: "value", label: "Value"}]} />
 *
 * Notes about usage:
 * - You can give each item an optional `label` property, and it will display that to the UI rather than `value`
 * - Although `label` is optional for the Select component, it's usually wise to always give inputs a label.
 * - The default behavior for this component is "uncontrolled", but you can pass in a `value` and `setValue`
 *   if you'd like to control the `<Select>`'s input.
 *
 * @param {array} items The list of items to render as options for the dropdown menu
 * @param {object} [value] The controlled value for the input (omitting it will default to "uncontrolled" behavior). Must be an existing object within items (or null)
 * @param {function} [setValue] The function that's used to control the value of the input (only required when providing `value`).
 * @param {boolean} [showError] Used to render the error message if one is present.
 * @param {string} [error] The error message to display.
 * @param {boolean} [disabled] Disable and style Select component.
 * @param {boolean} [loading] Displays a loading indicator when things are still loading.
 */
const Select = ({
  items,
  value,
  setValue,
  onMenuBlur,
  showError,
  error,
  disabled,
  loading,
  ...props
}) => {
  // Handles the switch to controlled behavior, for when
  // the caller wants to control the input's value (which is useful for Formik integration)
  const controlProps = {}
  if (setValue) {
    controlProps.selectedItem =
      items.find((item) => item.value === value) || VOID_SELECTED
    controlProps.onSelectedItemChange = (changes) =>
      setValue(changes.selectedItem.value)
  }

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items,
    initialSelectedItem: VOID_SELECTED,
    itemToString: (item) => item.label,
    onStateChange: ({ type, isOpen }) => {
      if (onMenuBlur && typeof onMenuBlur === 'function') {
        // We need to defer formik validation until the menu is closed, rather than when the <Input/> itself is selected.
        // Therefore, we let any consumer know when the menu blurs.
        if (
          type === useSelect.stateChangeTypes.MenuBlur ||
          type === useSelect.stateChangeTypes.MenuKeyDownEscape ||
          (type === useSelect.stateChangeTypes.ToggleButtonClick && !isOpen)
        ) {
          onMenuBlur()
        }
      }
    },
    ...controlProps
  })

  return (
    <Flex position='relative' flexDirection='column' flex='1 1 auto'>
      <Input
        type='button'
        noFocus
        value={selectedItem.label}
        error={showError ? error : undefined}
        disabled={disabled || loading}
        {...getToggleButtonProps()}
        {...props}
        labelProps={getLabelProps()}
        textAlign='left'
        data-testid={`select-for-${props.label}`}
      >
        <DropdownInlineContext showError={showError} pointerEvents='none'>
          {loading ? (
            <DropdownSpinner />
          ) : (
            <Chevron direction='down' active={isOpen} />
          )}
        </DropdownInlineContext>
      </Input>
      <Dropdown open={isOpen && items.length > 0} {...getMenuProps()}>
        {items.map((item, index) => (
          <DropdownItem
            as='li'
            {...getItemProps({ item, index })}
            selected={index === highlightedIndex}
            key={index}
          >
            {item.label || item.value}
          </DropdownItem>
        ))}
      </Dropdown>
    </Flex>
  )
}

Select.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.any,
  setValue: PropTypes.func,
  onMenuBlur: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.node,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default Select

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { useSubscribeContext } from 'context'
import { ErrorBoundary } from 'helpers'

import { Button } from 'ui/bend/elements'

import PromoCodeInput from './PromoCodeInput'
import MembershipPlanPicker from '../../../MembershipPlanPicker'
import SubscriptionDisclaimer from '../../SubscriptionDisclaimer'
import InputText from '../../InputText'

const MembershipForm = ({
  cta,
  disclaimer,
  showPromoCode,
  showRedemptionCode,
  code
}) => {
  const { state } = useSubscribeContext()
  const { isSubmitting } = useFormikContext()

  const labelText = code ? '' : 'Redemption Code'
  const showPlans = state.membership && state.membership.plans.length > 1

  return (
    <Fragment>
      {showPromoCode && <PromoCodeInput />}
      {showRedemptionCode && (
        <InputText
          type='text'
          name='redemptionCode'
          label={labelText}
          autoComplete='off'
        />
      )}
      {showPlans && <MembershipPlanPicker />}
      <SubscriptionDisclaimer
        cta={cta}
        disclaimer={disclaimer}
        mt={showPlans ? '0px' : '8px'}
      />
      <Button
        type='submit'
        width='100%'
        processing={isSubmitting}
        id='submit_membership_button'
        data-testid='submit_membership_button'
        mt='16px'
      >
        {cta}
      </Button>
    </Fragment>
  )
}

MembershipForm.propTypes = {
  cta: PropTypes.string.isRequired
}

const SafeMembershipForm = (props) => (
  <ErrorBoundary>
    <MembershipForm {...props} />
  </ErrorBoundary>
)

export default SafeMembershipForm

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'

import { useTabContext } from './Tabs'

export const TabContent = styled('div')`
  // modify left & top to shoot the element far off screen, this causes it to not take up room needlessly while still allowing us to animate the transition in.
  left: ${(props) =>
    props.transitionState === 'entering' || props.transitionState === 'exited'
      ? '-99999px'
      : ''};
  top: ${(props) =>
    props.transitionState === 'entering' || props.transitionState === 'exited'
      ? '-99999px'
      : ''};
  position: ${(props) =>
    props.transitionState === 'entering' || props.transitionState === 'exited'
      ? 'absolute'
      : 'relative'};
  opacity: ${(props) => (props.transitionState === 'entered' ? 1 : 0)};
  visibility: ${(props) =>
    props.transitionState === 'entered' ? 'visible' : 'hidden'};
  transition: opacity ${(props) => props.duration}ms ease-in-out,
    visibility ${(props) => props.duration}ms ease-in-out;
`

/**
 * @param {string} title The title of the tab (will display in Tab Header). This also operates as the unique identifying key for the Tab and its Header.
 * @param {boolean} [disabled] Whether the tab is disabled
 * @param {string} [headerColor] An optional color for Tab header. Overrides all other color styles. -- Note that this prop is used in TabHeader, not in Tab.js itself
 * @param {boolean} [mountOnEnter] Will mount the Tab Body only after it is set as an active tab
 * @param {boolean} [unmountOnExit] Will unmount the Tab Body if it is no longer the active tab
 */

const Tab = ({
  disabled,
  title,
  mountOnEnter,
  unmountOnExit,
  children,
  onClick,
  ...delegated
}) => {
  const { id, bodyDuration, activeTab } = useTabContext()
  // This will be an unreachable tab. Do not render it.
  if (!title || disabled) return null

  const active = activeTab === title

  const onEnter = () => {
    return onClick ? onClick() : null
  }

  return (
    <Transition
      in={active}
      timeout={bodyDuration}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      onEnter={() => onEnter()}
    >
      {(transitionState) => (
        <TabContent
          title={title}
          active={active}
          transitionState={transitionState}
          id={`tab-pane-${id}-${title}`}
          aria-hidden={!active}
          role='tabpanel'
          aria-labelledby={`tab-${id}-${title}`}
          aria-live='polite'
          duration={bodyDuration}
          {...delegated}
        >
          {children}
        </TabContent>
      )}
    </Transition>
  )
}
Tab.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
  headerColor: PropTypes.string,
  mountOnEnter: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  onClick: PropTypes.func
}

export default Tab

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useUserContext } from 'context'
import { ErrorBoundary } from 'helpers'

import { P } from 'ui/bend/typography'
import FullSection from './FullSection'

// TODO: refactor so SignUpForm can dynamically render Full or Partial sections.

const SignUpForm = ({ cta, redirectBack, setShowSignInModal }) => {
  const { user } = useUserContext()

  return (
    <Fragment>
      {user ? (
        <P color='grey5'>
          Connected with {user.firstName} {user.lastName}
        </P>
      ) : (
        <FullSection
          cta={cta}
          redirectBack={redirectBack}
          setShowSignInModal={setShowSignInModal}
        />
      )}
    </Fragment>
  )
}

SignUpForm.propTypes = {
  cta: PropTypes.string.isRequired,
  redirectBack: PropTypes.bool
}

const SafeSignUpForm = (props) => (
  <ErrorBoundary>
    <SignUpForm {...props} />
  </ErrorBoundary>
)

export default SafeSignUpForm

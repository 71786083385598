import styled from 'styled-components'
import { space } from 'styled-system'
import { css } from '@styled-system/css'

import { Caption } from 'ui/bend/typography'

const Feedback = styled(Caption)(
  (props) =>
    css({
      display: 'block',
      ml: '16px',
      mt: '4px',

      '&.error': {
        color: props.theme.colors.errorDefault
      }
    }),
  space
)

export default Feedback

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { system } from 'styled-system'
import { css } from '@styled-system/css'

import { Box, Feedback } from 'ui/bend/elements'
import { Collapse } from 'ui/bend/animations'
import { defaultValues as pStyles } from 'ui/bend/typography/P'

export const Container = styled(Box)`
  position: relative;
  margin-top: 16px;
  flex-grow: 1;
`

export const Label = styled('label')((props) =>
  css({
    ...pStyles,
    position: 'absolute',
    left: '9px',
    top: '15px',
    px: '8px',
    cursor: 'text',
    color: props.theme.colors.grey5,
    transition: 'all .15s ease',

    '&.floating': {
      opacity: props.disabled ? '0' : '',
      background: props.theme.colors.base,
      color: props.theme.colors.grey6,
      fontSize: 1,
      top: '-9px'
    }
  })
)

export const BaseInput = styled('input')`
  ${system({
    textAlign: true
  })}
  ${(props) =>
    css({
      ...pStyles,
      width: '100%',
      color: props.theme.colors.primary,
      background: props.theme.colors.base,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: props.theme.colors.grey4,
      borderRadius: 0, // needed to override native iOS styling
      py: '13px',
      px: '16px',
      '-webkit-appearance': 'none', // needed to override native iOS styling

      '&:focus': {
        borderColor: props.theme.colors.primary,
        outline: '0'
      },

      '&.error': {
        borderColor: props.theme.colors.errorDefault
      },

      '&:disabled': {
        borderColor: props.theme.colors.grey4,
        background: props.theme.colors.grey2
      }
    })}
`

const Input = React.forwardRef(
  (
    {
      label,
      value,
      onFocus,
      onBlur,
      noFocus,
      feedback,
      children,
      containerProps,
      labelProps,
      error,
      customError,
      ...props
    },
    ref
  ) => {
    // Track whether input currently has focus.
    const focused = useRef(false)
    const [floating, setFloating] = useState(
      (value && value.length > 0) ||
        typeof value === 'number' ||
        Boolean(props.placeholder)
    ) // if there are prefilled values, then set floating to true.

    // Used in case the value is set outside of the current component, so neither the onBlur nor
    // the onFocus events would be fired.
    useEffect(() => {
      // A user can delete the current value but remain focused on the field.
      setFloating(
        (value && value.length > 0) ||
          typeof value === 'number' ||
          focused.current
      )
    }, [value])

    const handleFocus = (e) => {
      if (!noFocus) {
        if (onFocus && typeof onFocus === 'function') onFocus(e)
        focused.current = true
        setFloating(true)
      }
    }

    const handleBlur = (e) => {
      if (!noFocus) {
        if (onBlur && typeof onBlur === 'function') onBlur(e)
        if (typeof value !== 'number' && !value && !props.placeholder) {
          setFloating(false)
        }
        focused.current = false
      }
    }

    return (
      <Container {...containerProps}>
        <Label
          {...labelProps}
          htmlFor={props.id || props.name}
          className={floating && 'floating'}
          disabled={props.disabled}
        >
          {label}
        </Label>
        <BaseInput
          ref={ref}
          id={props.name}
          value={value}
          onBlur={handleBlur}
          onFocus={handleFocus}
          {...props}
          className={error && 'error'}
        />
        <Collapse isOpened={!!error}>
          {!!error && (
            <Feedback className='error'>
              {customError && typeof customError === 'function'
                ? customError(error)
                : error}
            </Feedback>
          )}
        </Collapse>
        <Collapse isOpened={!error && !!feedback}>
          {!error && feedback && <Feedback>{feedback}</Feedback>}
        </Collapse>
        {children}
      </Container>
    )
  }
)

Input.displayName = 'Input'

Input.propTypes = {
  label: PropTypes.string,
  feedback: PropTypes.string,
  error: PropTypes.string,
  noFocus: PropTypes.bool,
  children: PropTypes.node
}

export default Input

import styled from 'styled-components'
import { css } from '@styled-system/css'

import Text from './Text'

const H6 = styled(Text)(
  css({
    fontSize: [3, 4, 5],
    fontFamily: 'Proxima',
    lineHeight: '135%',
    fontWeight: '600'
  })
)

H6.defaultProps = {
  as: 'h6'
}

export default H6

import styled from 'styled-components'
import { css } from '@styled-system/css'

import Text from './Text'

const H5 = styled(Text)(
  css({
    fontSize: [4, 5, 6],
    fontFamily: 'Proxima',
    lineHeight: '135%',
    fontWeight: '600'
  })
)

H5.defaultProps = {
  as: 'h5'
}

export default H5

import styled from 'styled-components'
import { css } from '@styled-system/css'

import Text from './Text'

const H3 = styled(Text)(
  css({
    fontSize: [6, 7, 8],
    fontFamily: 'Proxima',
    letterSpacing: 0,
    lineHeight: '108%',
    fontWeight: 'semibold'
  })
)

H3.defaultProps = {
  as: 'h3'
}

export default H3

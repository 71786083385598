import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { P } from 'ui/bend/typography'
import { Check } from 'images/bend'

const CheckboxInput = styled('span')`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.grey5};
  border-radius: 2px !important; /* Need important to override legacy components.css */
  transition: all 0.3s ease-in-out;
`

const Label = styled(P)`
  display: inline;
  transition: all 0.3s ease-in-out;
`

const CheckboxContainer = styled('label')`
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  position: relative;
  display: inline-block;
  height: 24px;
  width: 40px;
  white-space: nowrap;
  padding-left: 32px;
  color: ${(props) => props.theme.colors.grey5};

  /* hide default input */
  input {
    display: contents;
    opacity: 0;
    width: 0;
    height: 0;
  }

  &:hover {
    ${P} {
      color: ${(props) => props.theme.colors.primary};
    }

    ${CheckboxInput} {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  input:checked {
    ~ ${P} {
      color: ${(props) => props.theme.colors.primary};
    }

    ~ ${CheckboxInput} {
      background-color: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
      svg {
        fill: ${(props) => props.theme.colors.base};
      }
    }
  }

  input:disabled {
    ~ ${P} {
      color: ${(props) => props.theme.colors.grey2};
    }

    ~ ${CheckboxInput} {
      background-color: ${(props) => props.theme.colors.grey2};
      border-color: ${(props) => props.theme.colors.grey2};
      svg {
        fill: ${(props) => props.theme.colors.grey2};
      }
    }
  }
`

CheckboxContainer.defaultProps = {
  as: 'label'
}

const Checkbox = ({ label, ...props }) => {
  return (
    <CheckboxContainer htmlFor={props.name}>
      <input
        id={props.name}
        type='checkbox'
        aria-label={label}
        checked={props.value}
        {...props}
      />
      <Label>{label}</Label>
      <CheckboxInput>
        <Check width='100%' />
      </CheckboxInput>
    </CheckboxContainer>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired
}

export default Checkbox

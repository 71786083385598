import React from 'react'
import PropTypes from 'prop-types'
import { UnmountClosed } from 'react-collapse'
import styled from 'styled-components'
import { layout, space } from 'styled-system'

// TODO: rework-animations
export const CollapseContainer = styled('div')(layout, space, {
  '& >.ReactCollapse--collapse': {
    transition: 'height 250ms cubic-bezier(0.4, 0, 0.2, 1)'
  }
})

const Collapse = ({
  Container = CollapseContainer,
  isOpened,
  children,
  onWork,
  onRest,
  ...rest
}) => (
  <Container {...rest}>
    <UnmountClosed isOpened={isOpened} onWork={onWork} onRest={onRest}>
      {children}
    </UnmountClosed>
  </Container>
)

Collapse.propTypes = {
  Container: PropTypes.node,
  isOpened: PropTypes.bool,
  children: PropTypes.node
}

export default Collapse

import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormikContext, useField } from 'formik'

import { useSubscribeContext } from 'context'

import { Collapse } from 'ui/bend/animations'
import {
  Box,
  PillButton as UnstyledPillButton,
  SVGImage
} from 'ui/bend/elements'
import { Caption } from 'ui/bend/typography'
import { Checkmark } from 'images/bend'
import InputText from '../../InputText'

const PillButton = styled(UnstyledPillButton)((props) => ({
  opacity: props.active ? '1' : '0',
  zIndex: props.active ? 10 : -10, // hide behind input until we want it displayed. Remember, display: can't animate!
  transition: 'opacity 0.25s ease-in-out'
}))

const PromoCode = () => {
  const { actions, state } = useSubscribeContext()
  const { values, setFieldTouched, setFieldValue } = useFormikContext()
  const [{ value }, meta, { setValue }] = useField('promoCode')

  // TODO: remove-candy-check fix submitted/error wobbly display for promo codes.
  const [submitted, setSubmitted] = useState(false)
  const [appliedPromotion, setAppliedPromotion] = useState(null)

  useEffect(() => {
    // If the user has a promoCode already set, update appliedPromotion accordingly.
    // This is necessary to display promoCode copy if the user moves on from the MembershipForm and then comes back to it.
    if (state.membership.promoCode) {
      const promoPlan = state.membership.plans.find(
        (plan) => plan.couponApplies
      )
      if (promoPlan) {
        setAppliedPromotion(promoPlan.comparisonDiscount)
      }
    }
  }, [])

  const handlePromoCodeCheck = async (e) => {
    e.preventDefault()
    if (value !== state.membership.promoCode) {
      try {
        const res = await actions.membership.setPromoCode(value)
        if (res) {
          // Set the promoCodeSet field value if promoCode exists.
          setFieldValue('promoCodeSet', true)
          // get the applied promotion and display it.
          // We do not want to use the comparison discount in state.membership.selectedPlan because state updates asynchronously and is not tied to values.promoCode set.
          const promotionalPlan = res.find((plan) => plan.couponApplies)
          if (promotionalPlan && promotionalPlan.comparisonDiscount) {
            setAppliedPromotion(promotionalPlan.comparisonDiscount)
          }
        }
      } catch (error) {
        // Clear out promoCodeSet if call to actions.membership.setPromoCode() errors out (e.g. invalid promo code requested)
        setFieldValue('promoCodeSet', false)
        // we only touch the promoCode field if we need to display an error.
        setFieldTouched('promoCode')
        setAppliedPromotion(false)
      } finally {
        setSubmitted(true)
      }
    }
  }

  const handleRemovePromoCode = async (e) => {
    e.preventDefault()
    await actions.membership.setPromoCode()
    setFieldValue('promoCodeSet', false)
    setFieldTouched('promoCode', false)
    const promoCodeInput = document.getElementById('promoCode')
    if (promoCodeInput) promoCodeInput.focus()
  }

  const handleFinishRemovePromoCode = ({ isFullyClosed }) => {
    // We run this callback when Collapse finishes, so that the appliedPromotion stays visible throughout the collapse transition.
    if (isFullyClosed) {
      setAppliedPromotion(null)
      setSubmitted(false)
      setValue('')
    }
  }

  return (
    <Fragment>
      <Box position='relative'>
        <InputText
          type='text'
          name='promoCode'
          label='Promo Code'
          onBlur={(e, helper, { setTouched }) => {
            if (!submitted) return
            setTouched({ promoCode: true })
          }}
          readOnly={values.promoCodeSet}
        />
        {state.membership.promoCode ? (
          <SVGImage
            position='absolute'
            top='15px'
            right='13px'
            fill='primary'
            SVG={Checkmark}
          />
        ) : (
          <PillButton
            position='absolute'
            top='13%'
            right='13px'
            onClick={handlePromoCodeCheck}
            active={value}
            variant='dim'
            tabIndex={value ? 0 : -1}
            id='apply_promo_code_button'
          >
            Apply
          </PillButton>
        )}
      </Box>

      <Collapse
        isOpened={Boolean(values.promoCodeSet)}
        onRest={handleFinishRemovePromoCode}
      >
        <Caption as='div' pl='13px' pt='4px'>
          Success! You're saving {appliedPromotion}
          <Caption
            as='span'
            variant='bold'
            onClick={handleRemovePromoCode}
            style={{ cursor: 'pointer' }}
          >
            &nbsp;<u>Clear</u>
          </Caption>
        </Caption>
      </Collapse>
    </Fragment>
  )
}

export default PromoCode

import styled from 'styled-components'
import { css } from '@styled-system/css'

import Text from './Text'

const H2 = styled(Text)(
  css({
    fontSize: [7, 8, 9],
    fontFamily: 'Arquitecta',
    letterSpacing: 2,
    lineHeight: '108%',
    fontWeight: 'black',
    textTransform: 'uppercase'
  })
)

H2.defaultProps = {
  as: 'h2'
}

export default H2

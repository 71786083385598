import { ToastContainer as UnstyledToastContainer, Slide } from 'react-toastify'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import 'react-toastify/dist/ReactToastify.css'

const ToastContainer = styled(UnstyledToastContainer)`
  ${css({
    maxWidth: ['100%', '500px'],
    width: '100%'
  })}
  display: flex;
  flex-direction: column;
  align-items: center;

  .Toastify__toast {
    background: ${(props) => props.theme.colors.primary};
    padding: 12px;
    ${css({ width: ['100%', 'fit-content'] })}
  }
`

ToastContainer.defaultProps = {
  closeButton: false,
  position: 'bottom-center',
  autoClose: false,
  hideProgressBar: true,
  transition: Slide,
  closeOnClick: false,
  draggablePercent: 40
}

export default ToastContainer

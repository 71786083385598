export default {
  navbar: {
    mobile: '60px',
    desktop: '80px'
  },
  progressBar: '6px',
  mobileMenuMax: '700px',
  modalMax: {
    mobile: '80vh',
    desktop: '65vh'
  }
}

// Footer heights are defined in v2/Footer/_footerHeight.js

import styled from 'styled-components'
import { css } from '@styled-system/css'

import Text from './Text'

const H4 = styled(Text)(
  css({
    fontSize: [5, 6, 7],
    fontFamily: 'Proxima',
    lineHeight: '135%',
    fontWeight: '600'
  })
)

H4.defaultProps = {
  as: 'h4'
}

export default H4

import baseTheme from './baseTheme'
import baseColors, { rgb } from './scales/colors'

const colors = {
  ...baseColors, // to support deprecated color references
  base: baseColors.black,
  primary: baseColors.white,
  grey1: baseColors.charcoal,
  grey2: baseColors.mine,
  grey3: baseColors.chicago,
  grey4: baseColors.dawn,
  grey5: baseColors.alto,
  grey6: baseColors.mercury,
  grey7: baseColors.smoke,
  featured: baseColors.featured,
  errorDefault: baseColors.error,
  errorBase: baseColors.errorLight, // This is in flux right now.
  success: baseColors.success,
  rgb: {
    base: rgb.black,
    primary: rgb.white
  }
}

const DarkTheme = {
  ...baseTheme,
  colors
}

export default DarkTheme

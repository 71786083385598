import styled from 'styled-components'
import { css } from '@styled-system/css'

import Grid from './Grid'

const GridContainer = styled(Grid)((props) =>
  css({
    gridTemplateColumns: props.theme.constants.grid.templateColumns,
    gridColumnGap: props.theme.constants.grid.columnGap
  })
)

export default GridContainer

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { Arrow } from 'images/bend'

const Container = styled('button')`
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.base};
  cursor: pointer;
  border-width: 0px;
  border-radius: 100%;
  padding: ${(props) => (props.condensed ? '10px' : '14px')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity 0.3s, visibility 0.3s ease-in-out;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06),
    0px 8px 16px rgba(35, 41, 54, 0.08);

  & svg {
    transform: ${(props) => {
      switch (props.direction) {
        case 'up': {
          return 'rotate(-90deg)'
        }
        case 'down': {
          return 'rotate(90deg)'
        }
        case 'left': {
          return 'rotate(180deg)'
        }
        case 'right':
        default: {
          // default case for arrow.svg
          return ''
        }
      }
    }};
    /* Pass fill prop down to svg and utilize styled system scale */
    ${({ fill }) =>
      css({
        fill
      })}
  }
`

const ArrowButton = ({
  onClick,
  show = true,
  direction = 'right',
  fill = 'primary',
  condensed = false,
  ...rest
}) => {
  return (
    <Container
      direction={direction}
      fill={fill}
      show={show}
      onClick={onClick}
      onKeyPress={onClick}
      condensed={condensed}
      {...rest}
    >
      <Arrow />
    </Container>
  )
}

ArrowButton.propTypes = {
  direction: PropTypes.string,
  condensed: PropTypes.bool,
  fill: PropTypes.string,
  onClick: PropTypes.func,
  show: PropTypes.bool
}

export default ArrowButton

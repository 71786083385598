import React from 'react'
import { useTheme } from 'styled-components'

const CircularProgressBar = ({
  progress,
  strokeWidth = 4, // 15px
  size = 48 // 48px
}) => {
  const center = size / 2
  const radius = center - strokeWidth / 2
  const circumference = 2 * Math.PI * radius
  const offset = ((100 - progress) / 100) * circumference

  const { colors } = useTheme()

  return (
    <svg width={size} height={size}>
      <circle
        fill={colors.base}
        stroke={colors.base}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
      />
      <circle
        fill='none'
        stroke={colors.alert.medium}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        // Rotate the second circle to get the progress bar to start from 12, rather than 3
        transform={`rotate(-90, ${center}, ${center})`}
      />
      <svg
        // TODO: figure out how to programmatically calculate the x and y positions
        x={18.5}
        y={15}
        width={14}
        height={18}
        viewBox='0 0 14 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.583332 16V0L13.5833 8.22857L0.583332 16Z'
          fill={colors.primary}
        />
      </svg>
    </svg>
  )
}

export default CircularProgressBar

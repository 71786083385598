const spacing = {
  huge: ['128px', null, '152px', '192px'],
  large: ['80px', null, '96px', '128px'],
  medium: ['64px', null, '72px', '96px'],
  small: ['40px', null, '48px', '64px'],
  condensed: ['32px', null, '40px', '48px'],
  tiny: ['16px', null, '20px', '24px']
}

export default spacing

import React, { Fragment } from 'react'

import { ErrorBoundary } from 'helpers'
import { Caption } from 'ui/bend/typography'
import Disclaimer from 'ui/acro/blocks/Disclaimers/Disclaimer'

const SubscriptionDisclaimer = ({
  cta = 'Start Trial',
  disclaimer,
  ...rest
}) => {
  return (
    <Fragment>
      <Caption as='div' color='grey5' {...rest}>
        {disclaimer && <Fragment>{disclaimer}&nbsp;</Fragment>}
        <Disclaimer
          reactivate={rest.reactivate}
          actionText={cta}
          Wrapper='span'
        />
      </Caption>
    </Fragment>
  )
}

const SafeSubscriptionDisclaimer = (props) => (
  <ErrorBoundary>
    <SubscriptionDisclaimer {...props} />
  </ErrorBoundary>
)

export default SafeSubscriptionDisclaimer

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { CTA } from 'ui/bend/typography'

export const TabTitle = styled(CTA)`
  position: relative;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) =>
    css({
      color: props.color
        ? props.color
        : props.active
        ? 'primary'
        : props.disabled
        ? 'grey2'
        : 'grey5'
    })}
  transition: color ${(props) => props.duration}ms ease-in-out;
  padding-top: 16px;
  padding-bottom: 16px;
`

/**
 * A private Component used to render out the Tab Header area of the Tab component.
 *
 * @param {number} duration The duration required to complete the change tab animation.
 * @param {string} id The unique id of the tab
 * @param {function} setActiveTab a setter function to indicate that this tab should be active
 * @param {string} title The text to display for this Tab Header. This also operates as the unique identifying key for the Tab and its Header.
 * @param {boolean} [active] Whether this tab is the active tab
 * @param {string} [color] Desired color of tab. This overwrites any style coloring.
 * @param {boolean} [disabled] Whether this tab should be able to be set as active.
 * @param {boolean} [last] If this is the last Header in the Tab list
 */

const TabHeader = forwardRef(
  (
    {
      active,
      color,
      disabled,
      duration,
      handleKeyDown,
      id,
      last,
      setActiveTab,
      title
    },
    ref
  ) => {
    const handleSelect = () => {
      if (!disabled) {
        setActiveTab(title)
      }
    }

    return (
      <TabTitle
        active={active}
        onClick={handleSelect}
        mr={last ? '' : '16px'}
        disabled={disabled}
        role='tab'
        id={`tab-${id}-${title}`}
        aria-controls={`tab-pane-${id}-${title}`}
        aria-selected={active}
        tabIndex={active ? '0' : '-1'}
        duration={duration}
        onKeyDown={handleKeyDown}
        ref={ref}
        color={color}
        variant='responsive'
      >
        {title}
      </TabTitle>
    )
  }
)

TabHeader.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  last: PropTypes.bool,
  setActiveTab: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

TabHeader.displayName = 'TabHeader'

export default TabHeader

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { system } from 'styled-system'
import { Transition } from 'react-transition-group'

import { Box } from 'ui/bend/elements'
import { Spinner } from 'ui/bend/animations'
import { P } from '../typography'

export const DropdownMenu = styled(Box)`
  background: ${(props) => props.theme.colors.base};
  border-radius: 4px;
  border-left: 1px solid ${(props) => props.theme.colors.grey4};
  border-right: 1px solid ${(props) => props.theme.colors.grey4};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey4};
  box-shadow: 0px 2px rgba(${(props) => props.theme.colors.rgb.primary}, 0.24),
    0px 0px 2px rgba(${(props) => props.theme.colors.rgb.primary}, 0.12);
  max-height: 275px;
  width: 100%;
  position: absolute;
  top: 75px;
  z-index: 10;
  transition: opacity ${(props) => props.transitionSpeed}ms ease-in-out;
  opacity: ${(props) =>
    props.state === 'entering' || props.state === 'entered' ? 1 : 0};
  visibility: ${(props) => (props.state === 'exited' ? 'hidden' : 'visible')};
  overflow-y: auto;
`

export const DropdownItem = styled(P)`
  height: 50px;
  padding-top: 13px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.grey1 : props.theme.colors.white};
`

export const DropdownButton = styled(Box)`
  padding-left: 8px;
  display: ${(props) => (props.visible ? undefined : 'none')};
`

export const DropdownInlineContext = styled(Box)`
  ${system({
    pointerEvents: true
  })}
  position: absolute;
  top: ${(props) => (props.showError ? '17%' : '28%')};
  right: 13px;
  box-shadow: ${(props) =>
    props.divider
      ? `-1px 0px 0px 0px ${
          props.focused ? props.theme.colors.primary : props.theme.colors.grey4
        }`
      : ``};
  width: 1.5em;
`

export const DropdownSpinner = styled(Spinner)`
  height: 24px;
  margin-left: 8px;
`

// Handles the animation necessary for the dropdown
// menu and items within the Select and Combobox components
const Dropdown = forwardRef(({ open, children, ...props }, ref) => {
  return (
    <Box {...props} aria-live='polite' ref={ref}>
      <Transition in={open} timeout={250} appear>
        {(state) => (
          <DropdownMenu as='ul' state={state} transitionSpeed={250}>
            {children}
          </DropdownMenu>
        )}
      </Transition>
    </Box>
  )
})

Dropdown.displayName = 'Dropdown'

Dropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node
}

export default Dropdown

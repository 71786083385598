import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Collapse } from 'ui/bend/animations'
import { CheckCircle, Box } from 'ui/bend/elements'
import { H6 } from 'ui/bend/typography'
import { useMultiStepForm } from './MultiStepForm'
import { useFormikContext } from 'formik'

const Container = styled(Box)({
  position: 'relative',
  height: 'auto'
  // transition: 'height 0.25s ease-in-out'
})

const FormStep = ({ title, children, goBack, index, ...rest }) => {
  const { stepNumber, goToStep } = useMultiStepForm()
  const { values, ...formikObject } = useFormikContext()
  const completed = stepNumber > index
  const active = stepNumber === index
  const clickable = completed && goBack

  const onClick = () => {
    if (completed && goBack) {
      if (typeof goBack === 'function') {
        goBack(values, formikObject)
      }
      goToStep(index)
    }
  }

  const cursor = clickable ? 'pointer' : 'inherit'

  return (
    <Container {...rest}>
      <H6
        color={completed ? 'primary' : active ? 'primary' : 'grey5'}
        onClick={onClick}
        cursor={cursor}
      >
        <CheckCircle
          completed={completed}
          active={active}
          variant='solid'
          mr='12px'
        />
        {title}
      </H6>
      <Collapse isOpened={completed || active} mx='32px'>
        {children}
      </Collapse>
    </Container>
  )
}

FormStep.propTypes = {
  children: PropTypes.node,
  completed: PropTypes.bool,
  handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  submitOnce: PropTypes.bool,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
}

export default FormStep

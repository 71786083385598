/****************************
 * SplitView is a layout container.
 * On VP1-2 the {right} component is on top with {left} below it.
 * On VP3+ it is bisected 50/50 between {left} and {right}
 *
 * Props:
 *  left: React Node
 *  Right: React Node
 ******************************/

import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

import { ErrorBoundary } from 'helpers'
import { MainContainer, GridItem } from 'ui/bend/elements'
import { componentHeights as heights } from 'ui/bend/themes'

const SplitView = ({ left, right, invertMobile }) => {
  const { spacing, constants } = useTheme()

  return (
    <MainContainer>
      <GridItem
        gridColumn={['full-start / full-end', null, 'col-end 6 / full-end']}
        gridRow={[invertMobile ? '2' : '1', null, '1']}
        position='relative'
      >
        {right}
      </GridItem>
      <MainContainer
        gridColumn={['full-start / full-end', null, 'full-start / col-start 7']}
        mr={[null, null, `-${constants.grid.columnGap[1]}`]}
        gridRow={[invertMobile ? '1' : '2', null, '1']}
        mb={[invertMobile ? '24px' : '', null, '0px']}
        pt={spacing.small}
        justifyContent='center'
        overflowY='auto'
        height={[null, null, `calc(100vh - ${heights.navbar.desktop})`]}
      >
        <GridItem gridColumn='main-start / main-end' flexDirection='column'>
          {left}
        </GridItem>
      </MainContainer>
    </MainContainer>
  )
}

SplitView.propTypes = {
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired
}

const SafeSplitView = (props) => (
  <ErrorBoundary>
    <SplitView {...props} />
  </ErrorBoundary>
)

export default SafeSplitView

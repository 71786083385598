import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { space, position, variant } from 'styled-system'

import Tag from 'ui/bend/typography/Tag'

const Badge = styled(Tag)(
  space,
  position,

  css({
    py: '2px',
    px: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'default'
  }),

  variant({
    variants: {
      solid: {
        color: 'base',
        bg: 'primary',
        borderColor: 'primary'
      },
      solidBlack: {
        color: 'white',
        bg: 'black',
        borderColor: 'black'
      },
      solidWhite: {
        color: 'black',
        bg: 'white',
        borderColor: 'white'
      },
      dim: {
        color: 'primary',
        bg: 'grey1',
        borderColor: 'grey1'
      },
      featured: {
        color: 'alert.medium',
        bg: 'base',
        borderColor: 'alert.medium'
      },
      featuredSolid: {
        color: 'white',
        bg: 'alert.medium',
        borderColor: 'alert.medium'
      }
    }
  })
)

Badge.defaultProps = {
  variant: 'solid'
}

const variantPropTypes = PropTypes.oneOf([
  'solid',
  'solidBlack',
  'solidWhite',
  'dim',
  'featured',
  'featuredSolid'
])

Badge.propTypes = {
  variant: PropTypes.oneOfType([
    variantPropTypes,
    PropTypes.arrayOf(variantPropTypes)
  ])
}

export default Badge

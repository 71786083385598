import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { layout, flexbox, space, variant, system } from 'styled-system'

import Spinner from 'ui/bend/animations/Spinner'
import { defaultValues as ctaValues } from 'ui/bend/typography/CTA'
import colors from 'ui/bend/themes/scales/colors'

//
// TODO:
// - Processing state
//

const StyledButton = styled('button')(
  layout,
  flexbox,
  space,
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '48px',
    minWidth: '100px',
    transition: '.15s ease-in-out'
  },
  css({
    ...ctaValues,
    px: '32px',
    py: '14px'
  }),
  system({
    whiteSpace: true
  }),
  variant({
    variants: {
      solid: {
        bg: 'primary',
        color: 'base',
        border: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'primary',
        '&:hover:not(.processing)': {
          bg: ['primary', 'grey6'],
          borderColor: ['primary', 'grey6'],
          color: 'base'
        },
        '&:focus': {
          color: 'base'
        },
        '&:active': {
          color: 'base'
        },
        '&:disabled:not(.processing)': {
          bg: 'grey3',
          borderColor: 'grey3'
        }
      },
      ghost: {
        bg: 'transparent',
        color: 'primary',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey3',
        '&:hover:not(.processing)': {
          bg: ['transparent', 'grey2'],
          borderColor: ['grey4', 'grey2'],
          color: 'primary'
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: 'primary'
        },
        '&:disabled:not(.processing)': {
          bg: 'transparent',
          borderColor: 'grey3',
          color: 'grey3'
        }
      },
      dim: {
        bg: 'grey1',
        color: 'primary',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey1',
        '&:hover:not(.processing)': {
          bg: ['grey1', 'grey2'],
          borderColor: ['grey1', 'grey2'],
          color: 'primary'
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: 'primary'
        },
        '&:disabled:not(.processing)': {
          bg: 'grey1',
          borderColor: 'grey1',
          color: 'grey4'
        }
      },
      alert: {
        bg: 'alert.medium',
        color: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'alert.medium',
        '&:hover:not(.processing)': {
          bg: ['alert.medium', `${colors.alert.medium}A6`], // 65% opacity.
          borderColor: ['alert.medium', `${colors.alert.medium}A6`], // 65% opacity.
          color: 'white'
        },
        '&:focus': {
          color: 'white'
        },
        '&:active': {
          color: 'white'
        },
        '&:disabled:not(.processing)': {
          bg: `${colors.alert.medium}59`, // 35% opacity.
          borderColor: `${colors.alert.medium}59`, // 35% opacity.
          color: 'grey4'
        }
      },
      ouline: {
        bg: 'transparent',
        color: 'primary',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'primary',
        fontSize: '1.125rem',
        fontWeight: '950',
        lineHeight: '1.5rem',
        '&:hover:not(.processing)': {
          bg: ['transparent', 'primary'],
          borderColor: 'primary',
          color: 'base'
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: 'primary'
        },
        '&:disabled:not(.processing)': {
          bg: 'transparent',
          borderColor: 'grey3',
          color: 'grey3'
        }
      }
    }
  })
)

const Button = ({
  children,
  disabled,
  processing,
  variant = 'solid',
  ...rest
}) => {
  return (
    <StyledButton
      disabled={disabled || processing}
      className={processing && 'processing'}
      variant={variant}
      {...rest}
    >
      {processing ? <Spinner /> : children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Button

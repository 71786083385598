import styled from 'styled-components'
import { css } from '@styled-system/css'

import Text from './Text'

const Display = styled(Text)(
  css({
    fontFamily: 'Arquitecta',
    fontWeight: 'black',
    fontSize: [9, 10, 11],
    letterSpacing: 2,
    lineHeight: '108%',
    textTransform: 'uppercase'
  })
)

Display.defaultProps = {
  as: 'h1'
}

export default Display

import React from 'react'
import styled from 'styled-components'
import { variant } from 'styled-system'

import Box from './Box'
import { Check } from 'images/bend'

// I can't get object notation to work well with both css({}) and variant({}), especially when interpreted props are involved.
const Container = styled(Box)`
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  height: 20px;
  width: 20px;
  padding: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in-out;
  ${({ active, completed }) =>
    variant({
      variants: {
        solid: {
          borderColor: active || completed ? 'primary' : 'grey3',
          bg: completed ? 'primary' : 'base',
          '& > svg': {
            fill: active ? 'primary' : 'base'
          }
        },
        dim: {
          borderColor: 'grey1',
          bg: 'grey1',
          '& > svg': {
            fill: 'primary'
          }
        }
      }
    })}
`

const CheckCircle = ({ active, completed, ...rest }) => {
  return (
    <Container active={active} completed={completed} {...rest}>
      <Check height='100%' width='100%' />
    </Container>
  )
}

CheckCircle.defaultProps = {
  variant: 'solid'
}

export default CheckCircle

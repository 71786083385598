import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { P } from 'ui/bend/typography'
import renderPropComponent from 'helpers/renderPropComponent'

const Loading = ({
  children,
  loading,
  error,
  ErrorComponent,
  LoadingComponent
}) => {
  return (
    <Fragment>
      {error && renderPropComponent(ErrorComponent)}
      {loading && renderPropComponent(LoadingComponent)}

      {!loading && !error && children}
    </Fragment>
  )
}

Loading.defaultProps = {
  LoadingComponent: <></>,
  ErrorComponent: (
    <P>We apologize, but there has been an error getting this information</P>
  )
}

Loading.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  error: PropTypes.bool
}

export default Loading

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import {
  layout,
  flexbox,
  position,
  space,
  variant,
  system
} from 'styled-system'

import Spinner from 'ui/bend/animations/Spinner'
import { defaultValues as bodyValues } from 'ui/bend/typography/P'

// TODO: Might make this a subset of Button... they're SOOOooo close.

// TODO:
// - Processing state
//
const StyledButton = styled('button')(
  layout,
  flexbox,
  space,
  position,
  {
    alignItems: 'center',
    display: 'flex',
    borderRadius: '100px !important', // we use !important to override legacy css.
    justifyContent: 'center',
    textAlign: 'center',
    transition: '.15s ease-in-out'
  },
  css({
    ...bodyValues,
    px: '16px',
    py: '6px'
  }),
  system({
    whiteSpace: true,
    transform: true
  }),
  variant({
    variants: {
      solid: {
        bg: 'primary',
        color: 'base',
        border: 'none',
        fill: 'base',
        '&:hover:not(.processing)': {
          bg: 'grey6',
          color: 'base'
        },
        '&:focus': {
          color: 'base'
        },
        '&:active': {
          color: 'base'
        },
        '&:disabled:not(.processing)': {
          bg: 'grey3'
        },
        '&.processing': {
          bg: 'grey6'
        }
      },
      ghost: {
        bg: 'transparent',
        color: 'primary',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey3',
        fill: 'primary',
        '&:hover:not(.processing)': {
          bg: 'grey2',
          borderColor: 'grey2'
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: 'primary'
        },
        '&:disabled:not(.processing)': {
          bg: 'transparent',
          borderColor: 'grey3',
          color: 'grey3',
          fill: 'grey3'
        }
      },
      dim: {
        bg: 'grey1',
        color: 'primary',
        fill: 'primary',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey1',
        '&:hover:not(.processing)': {
          bg: 'grey2',
          borderColor: 'grey2',
          color: 'primary'
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: 'primary'
        },
        '&:disabled:not(.processing)': {
          bg: 'grey1',
          borderColor: 'grey1',
          color: 'grey3',
          fill: 'grey3'
        }
      },
      dim: {
        bg: 'white',
        color: 'black',
        fill: 'black',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'white',
        '&:hover:not(.processing)': {
          bg: 'grey2',
          borderColor: 'grey2',
          color: 'primary'
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: 'primary'
        },
        '&:disabled:not(.processing)': {
          bg: 'grey1',
          borderColor: 'grey1',
          color: 'grey3',
          fill: 'grey3'
        }
      }
    }
  })
)

const PillButton = ({
  children,
  disabled,
  processing,
  variant = 'solid',
  ...rest
}) => {
  return (
    <StyledButton
      disabled={disabled || processing}
      className={processing && 'processing'}
      variant={variant}
      {...rest}
    >
      {processing ? <Spinner /> : children}
    </StyledButton>
  )
}

PillButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default PillButton

import styled from 'styled-components'
import { layout, space } from 'styled-system'

import { Chevron } from 'images/bend'

const ChevronBase = styled(Chevron).withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop)
})`
  transition: transform 0.3s ease-in-out, fill 0.3s ease-in-out;
  transform: ${(props) => {
    switch (props.direction) {
      case 'up':
        return props.active ? '' : 'rotate(180deg)'
      case 'down':
        return props.active ? 'rotate(180deg)' : ''
      case 'left':
        return props.active ? 'rotate(-90deg)' : 'rotate(90deg)'
      case 'right':
        return props.active ? 'rotate(90deg)' : 'rotate(-90deg)'
    }
  }};
  fill: ${(props) =>
    props.fill
      ? props.fill
      : props.active
      ? props.invertColor
        ? props.theme.colors.base
        : props.theme.colors.primary
      : props.invertColor
      ? props.theme.colors.base
      : props.theme.colors.grey5};
  ${layout}
  ${space}
`

ChevronBase.defaultProps = {
  height: '20px',
  width: '20px'
}

export default ChevronBase

import React, { createContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Flex } from 'ui/bend/elements'
import { ErrorBoundary } from 'helpers'

import TabHeader from './TabHeader'

const Store = createContext()
export const useTabContext = () => React.useContext(Store)

export const TabHeaderContainer = styled(Flex)`
  position: relative;
  height: 32px;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${(props) => props.theme.colors.grey2};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: ${(props) => props.underlineStyle.left};
    width: ${(props) => props.underlineStyle.width};
    transition: width ${(props) => props.duration}ms ease-in-out,
      left ${(props) => props.duration}ms ease-in-out;
    height: 1px;
    background: ${(props) => props.theme.colors.primary};
  }
`

/**
 * Locates the first tab that can be active.
 * A tab can be active IFF it is not disabled AND it has a title.
 */
const findFirstActiveTab = (children) => {
  let startingTab
  const childArray = React.Children.toArray(children)

  for (const child of childArray) {
    if (!child.props.disabled && child.props.title) {
      startingTab = startingTab || child.props.title

      if (startingTab && child.props.active) {
        startingTab = child.props.title
        // We have found the first element that isn't disabled and has a title.
        // and have the active prop
        // Set that as our starting index and bail out.
        break
      }
    }
  }

  return startingTab
}

/**
 * The `<Tabs>` component is used to render out a tab header that, when clicked, renders out a tab body.
 *
 * To use the `<Tabs>` component, you must supply it with `<Tab/>` children each with a required, unique `title={string}` prop and an optional `disabled={boolean}`.
 * This `title` prop is used to populate the Tab header row.
 * Failure to provide a `title` prop will result in the tab not being displayed.
 * Providing non-unique `title`s will throw an error.
 *
 * Example usage:
 * <Tabs id='test' duration={500}>
 *  <Tab title='First'>This tab is enabled and will remain mounted</Tab>
 *  <Tab title='Second' disabled>This tab is disabled. The title will mount, but the body will not.</Tab>
 *  <Tab>This div has no title, therefore it will not mount in either headers or body</Tab>
 *  <Tab title='Fourth' mountOnEnter unmountOnExit>This div is enabled and it will mount and unmount depending on its tab header's active state</Tab>
 * </Tabs>
 *
 * @param {string} id A unique identifier for the Tab component. Used to tie tab headers with tab bodies.
 * @param {number} duration The amount of time animations should take, in ms. Defaults to 300.
 */
const Tabs = ({ id, duration = 300, children, ...delegated }) => {
  /**
   * Tab body animation includes two waterfall animations - fade-out of old components and fade-in of new components.
   * Conversely, Tab header animation includes only one animation timer - moving the line.
   * Therefore, body animations should each take 1/2 the time of the header animation.
   * This will ensure that the body animations and header animation complete at the same time.
   */
  const bodyDuration = Math.floor(duration / 2)
  const [activeTab, setActiveTab] = useState(() => findFirstActiveTab(children))
  const [underlineStyle, setUnderlineStyle] = useState({
    width: 100,
    left: 0
  })
  const headerRef = useRef()
  const tabsRef = useRef({})

  /* Error Checking -- Ensure that every child has a unique title that we can use as a key */
  useEffect(() => {
    const childTitles = React.Children.toArray(children).map(
      (child) => child.props.title
    )

    const isUnique = (arr) => {
      let seenValues = {}
      for (let value of arr) {
        if (value) {
          if (seenValues[value]) {
            return false
          } else {
            seenValues[value] = true
          }
        }
      }
      return true
    }

    if (!isUnique(childTitles)) {
      throw new Error('TabBody Components must have unique titles')
    }
  }, [children])

  const getNextAvailableTab = (offset) => {
    if (headerRef.current && tabsRef.current) {
      let nodes = []
      let currentTab

      // iterate our tabs and remove any disabled ones (we don't want to land on them) and set our currently active one.
      for (let i in tabsRef.current) {
        const node = tabsRef.current[i]
        if (!node.disabled) nodes.push(node)
        if (node.active) currentTab = node
      }

      // Perform index wrapping if necessary.
      let index = nodes.indexOf(currentTab) + offset
      if (index >= nodes.length) index = 0
      if (index < 0) index = nodes.length - 1

      // Once we know where we should end up, pluck that element from our nodes array so we can set our activeTab and focus on it.
      const nextNode = nodes[index]
      if (nextNode) {
        setActiveTab(nextNode.title)
        nextNode.element.focus()
      }
    }
  }

  /* Make a weak keyboard trap on tab headers, such that arrow keys navigate and tab key can get us out of there */
  const handleKeyDown = (event) => {
    let nextActiveChild

    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowUp':
        nextActiveChild = getNextAvailableTab(-1)
        break
      case 'ArrowRight':
      case 'ArrowDown':
        nextActiveChild = getNextAvailableTab(1)
        break
      default:
        return
    }

    if (!nextActiveChild) return
    event.preventDefault()
  }

  const selectActiveTab = () => {
    if (headerRef.current && tabsRef.current) {
      let activeTab
      const container = headerRef.current

      // Find the currently active tab header.
      for (let key of Object.keys(tabsRef.current)) {
        if (tabsRef.current[key].active) {
          activeTab = tabsRef.current[key].element
          break
        }
      }

      if (activeTab) {
        // Adjust the underline's width and left location accordingly.
        const width = `${activeTab.getBoundingClientRect().width}px`
        const left = `${
          activeTab.getBoundingClientRect().left -
          container.getBoundingClientRect().left
        }px`
        setUnderlineStyle({
          width,
          left
        })
      }
    }
  }

  /* Keep the tab underline bar width/position updated as we switch active tabs */
  useEffect(() => {
    selectActiveTab()
  }, [activeTab])

  /* Keep the underline in the right position on resize */
  useEffect(() => {
    const handleResize = () => {
      selectActiveTab()
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Store.Provider value={{ id, bodyDuration, activeTab }}>
      <TabHeaderContainer
        as='nav'
        role='tablist'
        underlineStyle={underlineStyle}
        id={`tab-header-${id}`}
        ref={headerRef}
        duration={duration}
        {...delegated}
      >
        {React.Children.map(children, (child, index) => {
          const { disabled, title, headerColor } = child.props

          // Don't bother rendering as there will be nothing to click within the header to activate a tab.
          if (!title) return null

          const last = index >= React.Children.count(children) - 1
          const active = activeTab === title

          return (
            <TabHeader
              key={`${id}-tab-header-${title}`}
              active={active}
              color={headerColor}
              disabled={disabled}
              duration={duration}
              handleKeyDown={handleKeyDown}
              id={id}
              last={last}
              setActiveTab={setActiveTab}
              title={title}
              ref={(element) =>
                (tabsRef.current[title] = { element, active, disabled, title })
              }
            />
          )
        })}
      </TabHeaderContainer>
      {children}
    </Store.Provider>
  )
}

Tabs.propTypes = {
  id: PropTypes.string.isRequired
}

const SafeTabs = (props) => (
  <ErrorBoundary>
    <Tabs {...props} />
  </ErrorBoundary>
)

export default SafeTabs

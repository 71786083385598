import styled from 'styled-components'
import { variant } from 'styled-system'
import { css } from '@styled-system/css'

import Text from './Text'

const defaultValues = {
  fontFamily: 'Proxima',
  fontWeight: 'regular',
  fontSize: 1,
  lineHeight: '115%'
}

const Caption = styled(Text)(
  variant({
    variants: {
      default: defaultValues,
      bold: {
        ...defaultValues,
        fontWeight: 'semibold'
      }
    }
  })
)

Caption.defaultProps = {
  variant: 'default'
}

export default Caption

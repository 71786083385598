import styled from 'styled-components'
import { variant } from 'styled-system'

import Text from './Text'

export const defaultValues = {
  fontFamily: 'Proxima',
  fontWeight: 'regular',
  fontSize: 1,
  lineHeight: '130%'
}

const Subtitle = styled(Text)(
  variant({
    variants: {
      default: {
        ...defaultValues
      },
      alternative: {
        ...defaultValues,
        fontWeight: 'bold',
        lineHeight: '105%',
        textTransform: 'uppercase'
      }
    }
  })
)

Subtitle.defaultProps = {
  variant: 'default'
}

export default Subtitle

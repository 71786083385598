import React, { useState, useContext } from 'react'

import { HR, Box } from 'ui/bend/elements'

const AccordionContext = React.createContext(null)

export const useAccordionContext = () => useContext(AccordionContext)

const Accordion = ({ defaultSelected, children, ...props }) => {
  const [selected, setSelected] = useState(defaultSelected)

  const handleSelect = (id) => {
    setSelected((prevSelected) => (prevSelected !== id ? id : null))
  }

  return (
    <AccordionContext.Provider value={{ selected, handleSelect }}>
      <Box {...props}>
        <HR my='22px' />
        {children}
      </Box>
    </AccordionContext.Provider>
  )
}

export default Accordion

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Collapse } from 'ui/bend/animations'
import { Flex } from 'ui/bend/elements'
import { P } from 'ui/bend/typography'

import { Chevron } from 'ui/bend/animations'
import { HR } from 'ui/bend/elements'
import { useAccordionContext } from './Accordion'
import { ErrorBoundary } from 'helpers'

const AccordionItem = ({ title, id, children, ...rest }) => {
  const { selected, handleSelect } = useAccordionContext()

  const isSelected = selected === id

  return (
    <Fragment>
      <Flex
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        onClick={() => handleSelect(id)}
        onKeyPress={() => handleSelect(id)}
        cursor='pointer'
        aria-expanded={isSelected}
        tabIndex='0'
        role='button'
        {...rest}
      >
        <P variant='bold' margin='0px' pr='20px' maxWidth='93%'>
          {title}
        </P>
        <Chevron direction='down' active={isSelected} />
      </Flex>
      <Collapse isOpened={isSelected}>
        <div style={{ paddingTop: '16px' }}></div>
        {children}
      </Collapse>
      {/* We use padding here because the margin on HR makes the AccordionItem jump on close */}
      <div style={{ paddingTop: '22px' }}></div>
      <HR />
      <div style={{ paddingTop: '22px' }}></div>
    </Fragment>
  )
}

const SafeAccordionItem = (props) => {
  return (
    <ErrorBoundary>
      <AccordionItem {...props} />
    </ErrorBoundary>
  )
}

SafeAccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}

export default SafeAccordionItem

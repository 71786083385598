import styled, { keyframes } from 'styled-components'
import { space, layout } from 'styled-system'

const SpinnerKeyframes = keyframes`
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
`

const Spinner = styled.div.attrs({
  'data-testid': 'fa-spinner'
})`
  position: relative;
  opacity: 1;
  transition: opacity linear 0.1s;
  ${space}

  &::before {
    ${layout}
    animation: 1s ease-in-out infinite ${SpinnerKeyframes};
    border: solid 3px ${(props) => props.theme.colors.grey4};
    border-top-color: currentColor;
    border-radius: 50%;
    content: '';
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    will-change: transform;
  }
`
Spinner.defaultProps = {
  height: '24px',
  width: '24px'
}

export default Spinner

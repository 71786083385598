import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * RangeSlider creates an <input type='range'/> component.
 * Because each browser creates sliders in a unique way, and because Chrome doesn't allow direct styling of the progress portion, we have to create two sliders and bind their values together.
 * For additional information, see: https://stackoverflow.com/questions/43771735/style-input-range-background-before-thumb
 */

const SliderContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 16px; /* tied to the height of our SliderKnob */
  display: ${(props) => props.display || 'inline-flex'};
`

// The base Slider component removes all browser formatting.

const Slider = styled('input')`
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
  }

  &::-moz-range-thumb {
    appearance: none;
  }
`

// SliderIndicator component colors both the background track and the amount already selected (via a box-shadow trick for Chrome & Safari, and direct targeting for Mozilla)
const SliderIndicator = styled(Slider)`
  pointer-events: none;

  &::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.colors.grey2};
    overflow: hidden;
    height: 2px;
    margin-top: 4px;
  }

  &::-webkit-slider-thumb {
    background: transparent;
    height: 1px;
    width: 1px;
    box-shadow: -7680px 0 0 7680px ${(props) => props.theme.colors.primary}; /* This supports range slider widths up to 8k monitor */
  }

  &::-moz-range-progress {
    background-color: ${(props) => props.theme.colors.primary};
    height: 2px;
  }

  &::-moz-range-track {
    background-color: ${(props) => props.theme.colors.grey2};
    height: 2px;
  }
`
const SliderKnob = styled(Slider)`
  cursor: pointer;

  &::-webkit-slider-runnable-track {
    background: transparent;
  }

  &::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.primary};
    margin-top: -2px; /* bump the slider knob up to center it accurately. */
  }

  &::-moz-range-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 0px;
    background: ${(props) => props.theme.colors.primary};
    margin-top: -2px; /* bump the slider knob up to center it accurately. */
  }
`

const RangeSlider = ({
  label,
  id,
  min = 0,
  max = 100,
  onChange,
  value,
  ...rest
}) => (
  <SliderContainer {...rest}>
    <SliderIndicator
      type='range'
      min={min}
      max={max}
      value={value}
      id={`${id}_slider`}
      onChange={(e) => e.preventDefault()}
      onTouchStart={(e) => e.preventDefault()}
      tabIndex={-1}
      aria-label={label}
    />
    <SliderKnob
      type='range'
      min={min}
      max={max}
      value={value}
      id={`${id}_knob`}
      onChange={onChange}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      aria-label={label}
    />
  </SliderContainer>
)

RangeSlider.propTypes = {
  label: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default RangeSlider

import styled from 'styled-components'
import { css } from '@styled-system/css'

import Text from './Text'

const Tag = styled(Text)(
  css({
    fontFamily: 'Arquitecta',
    fontSize: 1,
    fontWeight: 'bold',
    letterSpacing: '.04em',
    lineHeight: '140%',
    textTransform: 'uppercase'
  })
)

export default Tag

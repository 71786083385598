import React, { useEffect, useMemo, useReducer } from 'react'

import { Box, Grid, Flex } from 'ui/bend/elements'
import { P } from 'ui/bend/typography'

import Select from './Select'
import Input from './Input'

const getNumDays = (year, month) => new Date(year, month + 1, 0).getDate()

const reducer = (state, action) => {
  const formattedDate = new Date(state.time)

  switch (action.type) {
    case 'changeDay': {
      return {
        ...state,
        time: new Date(
          formattedDate.getFullYear(),
          formattedDate.getMonth(),
          action.payload,
          formattedDate.getHours(),
          formattedDate.getMinutes()
        ).getTime()
      }
    }
    case 'changeMonth': {
      return {
        ...state,
        time: new Date(
          formattedDate.getFullYear(),
          action.payload,
          formattedDate.getDate() <=
          getNumDays(formattedDate.getFullYear(), action.payload)
            ? formattedDate.getDate()
            : 1,
          formattedDate.getHours(),
          formattedDate.getMinutes()
        ).getTime()
      }
    }
    case 'changeYear': {
      return {
        ...state,
        time: new Date(
          action.payload,
          formattedDate.getMonth(),
          // Used to handle leap year reset
          formattedDate.getDate() <=
          getNumDays(action.payload, formattedDate.getMonth())
            ? formattedDate.getDate()
            : 1,
          formattedDate.getHours(),
          formattedDate.getMinutes()
        ).getTime()
      }
    }
    case 'focus': {
      return {
        ...state,
        focused: true,
        input: formattedDate
          .toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })
          .split(' ')[0]
      }
    }
    case 'blur': {
      const match = /((?<hours>1[0-2]|0?[1-9]):(?<minutes>[0-5][0-9]))/.exec(
        state.input
      )

      if (match) {
        const { hours, minutes } = match.groups
        // Handle the case of the previous time being > 12 hours and not flip the meridian
        if (formattedDate.getHours() >= 12 && Number(hours) < 12) {
          formattedDate.setHours(Number(hours) + 12)
        } else {
          formattedDate.setHours(hours)
        }
        formattedDate.setMinutes(minutes)
        return {
          ...state,
          focused: false,
          time: formattedDate.getTime(),
          error: null
        }
      } else {
        return {
          ...state,
          focused: false,
          time: null,
          error:
            'invalid date: make sure to specify a date within the following format: HH:MM, and make sure hours is less than (or equal to) 12 and minutes is less than 60.'
        }
      }
    }
    case 'changeTime': {
      console.log('changeTime')
      return { ...state, input: action.payload }
    }
    case 'changePeriod': {
      const hours = formattedDate.getHours()

      if (action.payload === 'AM') {
        formattedDate.setHours(hours >= 12 ? hours - 12 : hours)
      } else {
        formattedDate.setHours(hours < 12 ? hours + 12 : hours)
      }
      return { ...state, time: formattedDate.getTime() }
    }
    default:
      return state
  }
}

const DateTimePicker = ({ value, setValue, error, children }) => {
  const [{ time, focused, input }, dispatch] = useReducer(reducer, {
    time: value ? new Date(value).getTime() : Date.now(),
    focused: false,
    input: (value ? new Date(value) : new Date())
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      .split(' ')[0]
  })

  useEffect(() => {
    if (setValue && typeof setValue === 'function')
      setValue(new Date(time).toUTCString())
    // Don't add setValue to the deps array, as its reference isn't stable
    // and will trigger an infinite re-render here.
  }, [time])

  const formattedDate = new Date(time)

  const yearOptions = useMemo(() => {
    return Array.from({ length: 15 }, (_, i) => {
      var date = new Date()
      date.setMonth(date.getMonth() + 12 * (i - 3))

      return {
        value: date.getFullYear(),
        label: String(date.getFullYear())
      }
    })
  }, [])

  return (
    <Box maxWidth='400px'>
      {/* 
        using grid here because gap is really nice, and gaps with flexbox is 
        still not widely supported :/ 
      */}
      <Grid gridTemplateColumns='2fr 1fr 2fr' gridGap='8px'>
        <Select
          name='month'
          label='Month'
          items={Array.from({ length: 12 }, (_, i) => ({
            value: i,
            label: new Date(formattedDate.getFullYear(), i).toLocaleDateString(
              'en-US',
              {
                month: 'long'
              }
            )
          }))}
          value={formattedDate.getMonth()}
          setValue={(value) =>
            dispatch({ type: 'changeMonth', payload: value })
          }
        />
        <Select
          name='day'
          label='Day'
          items={Array.from(
            {
              length: getNumDays(
                formattedDate.getFullYear(),
                formattedDate.getMonth()
              )
            },
            (_, i) => ({
              value: i + 1, // needs to be incremented by 1 since getDate is not zero-indexed
              label: String(i + 1)
            })
          )}
          value={formattedDate.getDate()}
          setValue={(value) => dispatch({ type: 'changeDay', payload: value })}
        />
        <Select
          name='year'
          label='Year'
          items={yearOptions}
          value={formattedDate.getFullYear()}
          setValue={(value) => dispatch({ type: 'changeYear', payload: value })}
        />
      </Grid>
      <Flex maxWidth='200px'>
        <Input
          label='Time'
          containerProps={{ marginRight: '8px' }}
          value={
            focused
              ? input
              : formattedDate
                  .toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                  })
                  .split(' ')[0]
          }
          onFocus={() => dispatch({ type: 'focus' })}
          onBlur={() => dispatch({ type: 'blur' })}
          onChange={(e) => {
            dispatch({ type: 'changeTime', payload: e.target.value })
          }}
        />
        <Box minWidth='96px'>
          <Select
            value={formattedDate.getHours() < 12 ? 'AM' : 'PM'}
            setValue={(value) =>
              dispatch({ type: 'changePeriod', payload: value })
            }
            items={[
              { value: 'AM', label: 'AM' },
              { value: 'PM', label: 'PM' }
            ]}
          />
        </Box>
      </Flex>
      {error && (
        <P color='errorDefault' mt='8px'>
          {error}
        </P>
      )}
    </Box>
  )
}

export default DateTimePicker

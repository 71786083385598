import styled from 'styled-components'
import { color, layout, space } from 'styled-system'
import { css } from '@styled-system/css'

const HR = styled('div')(
  space,
  color,
  layout,
  css({
    height: '1px'
  })
)

HR.defaultProps = {
  bg: 'grey3'
}

export default HR

import styled from 'styled-components'
import { layout, flexbox, space, variant, system } from 'styled-system'

// TODO: Move all new buttons to using this StyledButtonBase

const StyledButtonBase = styled('button')(
  layout,
  flexbox,
  space,
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transition: '.15s ease-in-out'
  },
  system({
    whiteSpace: true
  }),
  variant({
    variants: {
      solid: {
        bg: 'primary',
        color: 'base',
        border: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'primary',
        '&:hover:not(.processing)': {
          bg: ['primary', 'grey6'],
          borderColor: ['primary', 'grey6'],
          color: 'base'
        },
        '&:focus': {
          color: 'base'
        },
        '&:active': {
          color: 'base'
        },
        '&:disabled:not(.processing)': {
          bg: 'grey3',
          borderColor: 'grey3'
        }
      },
      ghost: {
        bg: 'transparent',
        color: 'primary',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey4',
        '&:hover:not(.processing)': {
          bg: ['transparent', 'grey2'],
          borderColor: ['grey4', 'grey2'],
          color: 'primary'
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: 'primary'
        },
        '&:disabled:not(.processing)': {
          bg: 'transparent',
          borderColor: 'grey3',
          color: 'grey3'
        }
      },
      dim: {
        bg: 'grey1',
        color: 'primary',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey1',
        '&:hover:not(.processing)': {
          bg: ['grey1', 'grey2'],
          borderColor: ['grey1', 'grey2'],
          color: 'primary'
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: 'primary'
        },
        '&:disabled:not(.processing)': {
          bg: 'grey1',
          borderColor: 'grey1',
          color: 'grey3'
        }
      }
    }
  })
)

export default StyledButtonBase

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { flexbox, layout, space, system } from 'styled-system'

import { RightChevron } from 'images'
import { defaultValues as ctaValues } from 'ui/bend/typography/CTA'
import SVGImage from './SVGImage'

const StyledLink = styled('a')(
  flexbox,
  layout,
  space,
  css({
    ...ctaValues,
    display: 'flex',
    alignItems: 'center',
    py: '8px',

    '&:hover': {
      color: 'grey6'
    }
  }),
  system({
    whiteSpace: true
  })
)

const Link = ({ disabled, children, ...rest }) => {
  return (
    <StyledLink disabled={disabled} className='unstyled' {...rest}>
      {children}
      <SVGImage SVG={RightChevron} width='15px' height='15px' ml='8px' />
    </StyledLink>
  )
}

Link.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.any
}

export default Link
